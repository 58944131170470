import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { apiRequest } from "src/async/apiUtils";
import TextAccordion from "src/components/Shared/Accordions/TextAccordion";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { H5 } from "src/components/Shared/Text/Headers";

const TestSmtpSettings = ({ workspaceId = null }) => {
  const [email, setEmail] = useState("");
  const [isEmailSendLoading, setIsEmailSendLoading] = useState(false);
  const [isEmailSendDisabled, setIsEmailSendDisabled] = useState(true);

  const sendTestEmail = async () => {
    try {
      setIsEmailSendLoading(true);
      setIsEmailSendDisabled(true);

      let body = {
        email,
      };
      await apiRequest("POST", workspaceId ? `/workspaces/:workspace_id/email/test` : `/settings/smtp/email/test`, { body, params: { workspace_id: workspaceId } }, { showToastMessage: true, onSuccess: () => {}, onFailure: () => {} });
      setEmail("");
    } catch (error) {
    } finally {
      setIsEmailSendDisabled(true);
      setIsEmailSendLoading(false);
    }
  };

  return (
    <div className="w-full max-w-4xl flex-shrink">
      <TextAccordion
        headerTextPosition="left"
        headerText="Advanced details">
        <div className="-ml-3 flex w-full flex-col justify-between  gap-y-3">
          <H5 caption="Enter in a testing email address and see if an email comes through.">Test your SMTP connection</H5>
          <div className="ml-3 flex w-full max-w-2xl items-center  gap-5">
            <div className="relative w-full">
              <Input
                name="test-email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setIsEmailSendDisabled(false);
                }}
                label="Email address"
                inline={true}
              />
            </div>
            <div className="relative">
              <Button
                className="px-3"
                disabled={isEmailSendDisabled}
                onClick={sendTestEmail}
                type="button">
                Submit{isEmailSendLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
              </Button>
            </div>
          </div>
        </div>
      </TextAccordion>
    </div>
  );
};

export default TestSmtpSettings;
