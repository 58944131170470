import { CalendarIcon } from "@heroicons/react/24/outline";
import { getStatusStyles } from "src/helpers/billingHelper";
import { classNames } from "src/helpers/classNames";
import { formatCurrency } from "src/helpers";
import { connect } from "react-redux";
import moment from "moment";

const PlanDetails = ({ planDetails }) => {
  const { className, label } = getStatusStyles(planDetails?.subscription_status);
  const { className: upcomingClassName, label: upcomingLabel } = getStatusStyles("upcoming");

  return (
    <>
      <div className="relative flex flex-col space-y-3 pb-2 ">
        <div className="rounded-md border p-4">
          <div className="mb-2 border-b pb-1 font-semibold">Current Plan</div>
          <div className="">
            <div className="flex w-full items-center gap-2 text-4xl font-semibold">
              {planDetails?.plan_details?.name || "Plan Name"} {<div className={classNames("flex h-8 items-center justify-center gap-2 rounded-lg border px-2 text-base", className)}>{label}</div>}
            </div>
            <div className="py-1 pl-0.5 text-sm opacity-75">{planDetails?.plan_details?.description || "No description provided."}</div>
          </div>
          <div className="mb-3 flex items-center  justify-between gap-1 space-y-2 pt-2">
            {planDetails?.plan_details?.pricing?.rates
              ?.filter((price) => price.interval === planDetails?.subscription?.recurring_type)
              .map((price, index) => {
                const originalAmount = price?.amount || 0;
                const discountedAmount = originalAmount - (price?.discount_amount || 0);
                const discountPercentage = price?.discount_percentage || 0;
                return (
                  <div
                    key={price?._id}
                    className="flex items-center space-x-2 rounded-md border px-2 py-1">
                    {discountPercentage > 0 && <span className="text-xl text-gray-400 line-through">{formatCurrency(originalAmount, planDetails?.currency)}</span>}
                    <span className="text-xl font-semibold text-gray-600">
                      {formatCurrency(discountedAmount, planDetails?.currency)}/{price?.interval} | <span className="text-sm font-medium">Billed {price?.interval}ly</span>
                    </span>
                    {discountPercentage > 0 && <span className="flex h-6 items-center justify-center gap-2 rounded border border-green-500 bg-green-50 px-2 py-1 text-xs font-semibold text-green-500">Save {discountPercentage}%</span>}
                    {index === 0 && planDetails?.pricing?.rates?.length > 1 && <span className="text-gray-400">|</span>}
                  </div>
                );
              })}
            {planDetails?.free_trial_days_left >= 0 && (
              <div className="w-[180px] rounded-md border border-blue-100 bg-blue-50 px-2 py-1">
                <span className="text-sm font-medium text-blue-600">Free Trial:</span>
                <span className="ml-2 text-lg font-semibold text-blue-800">{planDetails?.free_trial_days_left} day(s) left</span>
              </div>
            )}
          </div>

          {planDetails?.subscription_status !== "pending" && (
            <div className="my-3 flex items-center  justify-between border-t py-2 text-lg">
              <h3 className="font-semibold">{["canceled", "expired", "on_trial"].includes(planDetails?.subscription_status) ? (moment(planDetails?.end_date).format("MM-DD-YYYY") >= moment(new Date()).format("MM-DD-YYYY") ? "Expiring on" : "Expired on") : "Next Billing Date"}</h3>
              <div className="mt-2 flex items-center justify-center gap-1 font-medium text-gray-600">
                <CalendarIcon className="h-6 w-6" />
                {moment(planDetails?.end_date).format("MMM DD, YYYY")}
              </div>
            </div>
          )}
        </div>

        {planDetails?.upcoming_plan && (
          <div className="rounded-lg border p-2 px-4">
            <div className="mb-2 flex justify-between border-b pb-1 font-semibold">
              <span>Upcoming Plan</span>
              <span className="text-sm font-medium text-slate-600">
                Will automatically get activated on <span className="font-semibold">{moment(planDetails?.upcoming_plan?.start_date).format("MMM DD, YYYY")}</span>
              </span>
            </div>
            <div className="flex items-center justify-between ">
              <div>
                <div className="flex w-full items-center gap-2 text-lg font-semibold">
                  {planDetails?.upcoming_plan?.plan_details?.name}
                  {<div className={classNames("flex h-6 items-center justify-center gap-2 rounded border px-2 text-sm", upcomingClassName)}>{upcomingLabel}</div>}
                </div>
                <div className="py-1 pl-0.5 text-sm opacity-75">{planDetails?.upcoming_plan?.plan_details?.description}</div>
              </div>
            </div>
            <div className="!mt-auto inline-block rounded-md border p-1  px-2 text-sm font-semibold text-highlightColor">
              {`${formatCurrency(planDetails?.upcoming_plan?.plan_details?.pricing?.rates?.find((rate) => rate?.interval === planDetails?.upcoming_plan?.subscription?.recurring_type)?.amount - planDetails?.upcoming_plan?.plan_details?.pricing?.rates?.find((rate) => rate?.interval === planDetails?.upcoming_plan?.subscription?.recurring_type)?.discount_amount || 0)}`}
              <span className="font-medium text-gray-400"> / {planDetails?.upcoming_plan?.subscription?.recurring_type}</span> | Billed {planDetails?.upcoming_plan?.subscription?.recurring_type}ly
            </div>
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, {})(PlanDetails);
