import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { apiRequest } from "src/async/apiUtils";
import useFetch from "src/hooks/useFetch";
import DateSearch from "src/components/Activity/DateSearch";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import MultiSelectObjectFilter from "src/components/Shared/Table/MultiRowSelect/MultiSelectObjectFilter";
import SortOrder from "src/components/Shared/Table/SortOrder";
import Table from "src/components/Shared/Table/Table";
import TRHeader from "src/components/Shared/Table/TRHeader";
import TableOptions from "src/components/Shared/TableOptions";
import ResponseDetailsModal from "src/components/Forms/FormModals/ResponseDetailsModal";
import ResponseEntry from "src/components/Forms/ResponseEntry";

const FormResponse = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [responses, setResponses] = useState([]);
  const [meta, setMeta] = useState(null);
  const [formsLoaded, setFormsLoaded] = useState(false);

  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);

  const [startDate, setStateDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);

  const [sortDirection, setSortDirection] = useState("ASC");

  const [response, setResponse] = useState(null);

  const {
    response: { data: workspaces },
  } = useFetch(searchParams.get("workspace_id") ? "" : "/workspaces/list", { method: "post" });

  const {
    response: { data: pages },
  } = useFetch(searchParams.get("workspace_id") ? `/workspaces/:workspace_id/pages/list` : `/pages/list`, { method: "post", params: { workspace_id: searchParams.get("workspace_id") }, data: { page_type: "FORM" } });

  const {
    response: { data: users },
  } = useFetch(searchParams.get("workspace_id") ? `/workspaces/:workspace_id/users` : `/users/list`, { params: { workspace_id: searchParams.get("workspace_id") } });

  const {
    response: { data: groups },
  } = useFetch(searchParams.get("workspace_id") ? `/workspaces/:workspace_id/domo-groups` : `/groups`, { params: { workspace_id: searchParams.get("workspace_id") } });

  const handleSelectedItems = (itemsArray, setSelected) => {
    let updatedSelected = itemsArray.reduce((filteredItemsArray, item) => (item.selected ? [...filteredItemsArray, item.key] : filteredItemsArray), []);
    setSelected(updatedSelected);
    setOffset(0);
  };

  const getFormList = useCallback(async () => {
    try {
      setFormsLoaded(false);
      const res = await apiRequest("get", searchParams.get("workspace_id") ? `/workspaces/:workspace_id/forms/:form_id/responses` : `/forms/:form_id/responses`, {
        queries: { limit: limit || 20, offset: offset || 0, users: selectedUsers, workspaces: selectedWorkspaces, groups: selectedGroups, pages: selectedPages, start_date: startDate, end_date: endDate, sort_direction: sortDirection },
        params: { workspace_id: searchParams.get("workspace_id"), form_id: id },
      });

      setResponses(res?.data?.data || []);
      setMeta(res?.data?.meta || null);
    } catch (error) {
      if (error.message !== "CanceledError: canceled") {
        navigate("/");
      }
    } finally {
      setFormsLoaded(true);
    }
  }, [limit, offset, startDate, endDate, selectedUsers, selectedWorkspaces, selectedGroups, selectedPages, searchParams, id, navigate, sortDirection]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    getFormList();
  }, [getFormList]);

  return (
    <>
      <TableOptions
        leftContent={
          <DateSearch
            setStateDate={setStateDate}
            setEndDate={setEndDate}
            inline={true}
            placeholder={"Select dates"}
          />
        }></TableOptions>

      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:table-cell sm:pl-6">
              <div className="flex items-center gap-x-1">
                <p>User</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    xPlacement="bottomLeft"
                    filterSelect={true}
                    defaultOptions={users?.map((user) => {
                      return { key: user._id, value: user.name, selected: selectedUsers.includes(user._id), object: user };
                    })}
                    searchableFields={["name", "email"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedUsers);
                    }}
                    itemComponent={(item) => {
                      return (
                        <div className="relative flex flex-col gap-1">
                          <div className="leading-4">{item.name}</div>
                          <div className="text-xs leading-4 text-gray-400">{item.email}</div>
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              <div className="flex items-center gap-x-1">
                <p>Workspace</p>
                {workspaces?.length > 0 && (
                  <div className="flex">
                    <MultiSelectObjectFilter
                      filterSelect={true}
                      defaultOptions={workspaces?.map((workspace) => {
                        return { key: workspace._id, value: workspace.name, selected: selectedWorkspaces.includes(workspace._id), object: workspace };
                      })}
                      searchableFields={["name"]}
                      onChange={(e) => {
                        handleSelectedItems(e, setSelectedWorkspaces);
                      }}
                    />
                  </div>
                )}
              </div>
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              <div className="flex items-center gap-x-1">
                <p>Group</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={groups?.map((group) => {
                      return { key: group._id, value: group.name, selected: selectedGroups.includes(group._id), object: group };
                    })}
                    searchableFields={["name"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedGroups);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              <div className="flex items-center gap-x-1">
                <p>Page</p>
                <div className="flex">
                  <MultiSelectObjectFilter
                    filterSelect={true}
                    defaultOptions={pages?.map((page) => {
                      return { key: page._id, value: page.name, selected: selectedPages.includes(page._id), object: page };
                    })}
                    searchableFields={["name"]}
                    onChange={(e) => {
                      handleSelectedItems(e, setSelectedPages);
                    }}
                  />
                </div>
              </div>
            </th>
            <th
              scope="col"
              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
              <div className="flex items-center gap-x-1">
                <p>Date</p>
                <SortOrder
                  sortDirection={sortDirection}
                  setSortDirection={(value) => {
                    setSortDirection(value);
                    setOffset(0);
                  }}
                />
              </div>
            </th>
            <th
              scope="col"
              className="relative hidden rounded-tr-lg py-3.5 pl-3 pr-4 sm:table-cell sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </TRHeader>
        }
        colSpan="5"
        loaded={formsLoaded}
        dataExists={formsLoaded && responses?.length > 0}>
        {responses.map((response, i) => {
          return (
            <ResponseEntry
              key={response._id}
              response={response}
              depthLevel={0}
              viewResponse={(data) => setResponse(data)}
            />
          );
        })}
      </Table>
      <PaginationFooter
        itemName="Response record"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />
      <ResponseDetailsModal
        isOpen={response?._id ? true : false}
        response={response}
        onCancel={() => setResponse(null)}
      />
    </>
  );
};

export default FormResponse;
