import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DropdownWrapper from "src/components/Shared/Forms/DropdownWrapper";
import Image from "src/components/Shared/Image";
import LazyImage from "src/components/Shared/LazyImage";

const Logo = ({ children, ...props }) => {
  const [visibleLogo, setVisibleLogo] = useState(null);

  useEffect(() => {
    const logoExists =
      Object.keys(props?.workspaceDetails).length && (props?.me?.type === "user" || (props?.me?.type === "editor" && localStorage.getItem("impersonatedUserId"))) && !props?.workspaceDetails?.use_global_settings
        ? props?.workspaceDetails?.image_logo
          ? props?.workspaceDetails?.image_logo + "?" + Date.now()
          : null
        : props.site?.image_logo + "?" + Date.now();
    if (logoExists) {
      setVisibleLogo(logoExists);
    }
  }, [props?.workspaceDetails, props?.me, props.site]);

  const navigateToBaseURL = () => {
    window.location.href = "/";
  };

  return (
    <div className="flex h-[56px] max-w-60 flex-shrink-0 items-center">
      {children}
      {props?.workspaces?.length > 1 ? (
        <div>
          <DropdownWrapper
            data={props?.workspaces?.map((workspace) => ({
              ...workspace,
              image: workspace.square_logo || workspace.image_logo || workspace.image_favicon,
            }))}
            onClick={props?.clickWorkspace}
            currentId={props?.extractWorkspaceName}
            content={
              <div
                className="mb-[1px] h-12 sm:h-14 sm:py-[3px]"
                onClick={navigateToBaseURL}>
                <LazyImage
                  src={visibleLogo}
                  alt={"logo"}
                  width="100%"
                  height="100%"
                  effect="blur"
                  classes="object-contain object-left"
                  hideImageOnError
                />
              </div>
            }
            buttonLabel="Workspaces"
            buttonClass="w-full max-w-sm"
            buttonIcon={true}
            dropdownContainer="ml-11"
          />
        </div>
      ) : (
        <div
          className="sm:h-13 h-12 sm:py-[1px]"
          onClick={navigateToBaseURL}
          style={{ cursor: "pointer" }}>
          <Image
            image={visibleLogo}
            alt={"logo"}
            className="object-contain object-left"
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    workspaceDetails: state.workspaceDetails,
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, null)(Logo);
