import { Bars3BottomRightIcon, GlobeAltIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import QuickMenu from "src/components/Shared/QuickMenu";
import TRBody from "src/components/Shared/Table/TRBody";
import TableRow from "src/components/Shared/Table/TableRow";
import { authorizeUserComponentAccess } from "src/helpers/authorizeUserComponentAccess";
import { classNames } from "src/helpers/classNames";

const FormEntry = ({ workspaceId = null, form = null, setDeleteId = () => {}, updateForm = () => {}, queryParams = null, ...props }) => {
  const [items, setItems] = useState([]);
  const router = useLocation();
  useEffect(() => {
    let item = [];

    if ((!props?.isGlobal && !form?.is_global) || (props?.isGlobal && props?.isGlobal)) {
      if (authorizeUserComponentAccess(props?.user, workspaceId, "page", ["update"])) {
        item.push({ name: "Edit", onClick: () => updateForm(form), color: "text-grey-500 hover:text-highlightColor" });
      }
      if (authorizeUserComponentAccess(props?.user, workspaceId, "page", ["update"])) {
        item.push({ name: "Delete", onClick: () => setDeleteId(form._id), color: "text-red-500" });
      }
    }
    setItems(item);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.isGlobal, props?.user, form, workspaceId]);

  return (
    <TableRow>
      <TRBody className="whitespace-pre-wrap">
        <div className={"flex items-center gap-3 " + (props.depthLevel > 0 ? `pl-${props.depthLevel}` : "")}>
          <div className="relative flex flex-col font-medium text-gray-900 sm:flex-row sm:items-center">
            <div>{form.name}</div>
            {form?.is_global && !props?.isGlobal && (
              <>
                <div
                  data-tooltip-id={`${form._id}-is_global`}
                  className="-ml-1 mt-1 flex w-[60px] cursor-default items-center gap-x-2 rounded bg-gray-50 px-[3px] py-[2px] text-xs text-gray-300 sm:m-0 sm:ml-3 sm:w-20 sm:bg-transparent">
                  <GlobeAltIcon className="h-4 w-4 sm:h-5 sm:w-5" />
                  Global
                </div>
                <ReactTooltip
                  id={`${form._id}-is_global`}
                  delayShow={200}
                  positionStrategy="fixed"
                  className="rounded bg-gray-700 px-2 py-2 opacity-100">
                  <div className="font-normal leading-[10px]">Global forms are managed by admins.</div>
                </ReactTooltip>
              </>
            )}
          </div>
        </div>
      </TRBody>
      <TRBody className="whitespace-pre-wrap">
        <Link to={`/forms/edit/${form._id}?tab=Responses&workspace_id=${workspaceId || ""}&is_global=${form?.is_global}&pathname=${router.pathname}${router.search}`}>
          <div className={classNames("flex w-12 cursor-pointer gap-x-1 font-medium transition-all duration-100 hover:underline")}>
            <Bars3BottomRightIcon className="h-5 w-5" />
            {form?.form_responses?.length || 0}
          </div>
        </Link>
      </TRBody>
      <TRBody className="whitespace-pre-wrap">
        <div className="flex justify-end">
          <QuickMenu
            items={items}
            disabled={items?.length === 0 ? true : false}
          />
        </div>
      </TRBody>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(FormEntry);
