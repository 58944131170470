import { EyeIcon, EyeSlashIcon, ArrowPathIcon, ExclamationTriangleIcon, ShieldExclamationIcon, ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import { classNames } from "src/helpers/classNames";

const Input = ({
  autoComplete = "off",
  label,
  name,
  placeholder,
  disabled = false,
  value = "",
  error = false,
  type = "text",
  inputClassNames = "",
  labelClassNames = "",
  onChange = () => {},
  onFocus = () => {},
  onKeyUp = () => {},
  onKeyDown = () => {},
  onBlur = () => {},
  loading = false,
  errorType = "",
  errorText = "",
  inline = false,
  wrapperClass,
  inputRef = null,
  autoGrowWidth = false, // <-- New prop for horizontal auto-grow
}) => {
  const labelClasses = classNames(labelClassNames, "flex text-sm font-medium", inline ? "" : "mb-1", !inline && disabled && "cursor-not-allowed text-gray-400", inline && disabled && "bg-transparent text-gray-300");

  const inputBaseClasses = classNames(
    autoGrowWidth ? "" : "w-full",
    "block sm:text-sm",
    inline ? "h-8 rounded-none border-none px-3 py-0 leading-none shadow-none md:h-7 md:px-2 2xl:h-8 2xl:px-3 focus:ring-0" : "h-[47px] md:h-[40px] 2xl:h-[47px] border-gray-300 rounded-md focus:ring-0 focus:border-highlightColor",
    error && "border-red-300",
    disabled ? "text-gray-400" : "text-gray-700",
    !inline && disabled && "cursor-not-allowed",
    inline && disabled && "bg-transparent",
  );

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const passwordInput = useRef(null);

  const handleChangePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  useEffect(() => {
    if (passwordInput?.current) {
      passwordInput.current.type = passwordVisibility ? "text" : type;
    }
  }, [passwordVisibility, type]);

  // Refs for auto-grow
  const inputEl = inputRef || passwordInput;
  const measureRef = useRef(null);

  useEffect(() => {
    if (autoGrowWidth && inputEl.current && measureRef.current) {
      // Match styles of the hidden span to the input to get accurate measurements
      const style = window.getComputedStyle(inputEl.current);
      measureRef.current.style.fontSize = style.fontSize;
      measureRef.current.style.fontFamily = style.fontFamily;
      measureRef.current.style.fontWeight = style.fontWeight;
      measureRef.current.style.letterSpacing = style.letterSpacing;
      measureRef.current.style.whiteSpace = "pre";

      // Set the measure text to the input value + an extra character for caret space
      measureRef.current.textContent = value || placeholder || "";

      const newWidth = measureRef.current.scrollWidth + 20; // +10px for some padding
      inputEl.current.style.width = newWidth + "px";
    }
  }, [value, placeholder, autoGrowWidth, inputEl]);

  return (
    <>
      <div className={classNames("w-full", inline ? "relative overflow-hidden rounded-md border px-0 pt-2 focus-within:border-highlightColor md:pt-1 2xl:pt-2" : "relative", inline && disabled ? "border-gray-100 bg-gray-50" : "border-gray-300", wrapperClass)}>
        {label && (
          <label
            htmlFor={name}
            className={inline ? classNames("m-0 pl-3 text-xs font-semibold uppercase leading-none text-gray-400 md:pl-2 md:text-[10px] 2xl:pl-3", labelClasses) : labelClasses}>
            {label} {error && <span className="pl-1 text-base font-semibold !leading-3 text-red-500">*</span>}
          </label>
        )}
        <div className="w-full">
          <input
            ref={inputEl}
            disabled={disabled}
            value={value}
            type={type}
            name={name}
            autoComplete={autoComplete}
            onFocus={onFocus}
            onChange={onChange}
            onKeyDown={onKeyDown}
            onBlur={onBlur}
            onKeyUp={onKeyUp}
            className={inputClassNames ? inputClassNames + " h-[47px] md:h-[40px] 2xl:h-[47px]" : inputBaseClasses}
            placeholder={placeholder}
            // If using autoGrowWidth, we might start with a minimal width or a default width
            style={autoGrowWidth ? { width: "auto", minWidth: "50px" } : {}}
          />
          {type === "password" && (name === "password" || name === "confirm-password") && (
            <Button
              type="button"
              version="default"
              className="absolute bottom-0 right-0 flex h-10 w-10 items-center justify-center bg-transparent text-slate-500"
              onClick={handleChangePasswordVisibility}>
              {!passwordVisibility ? <EyeSlashIcon className="h-5 w-5" /> : <EyeIcon className="h-5 w-5" />}
            </Button>
          )}
          {loading && (
            <Button
              type="button"
              version="default"
              className="absolute bottom-0 right-0 flex h-10 w-10 cursor-default items-center justify-center bg-transparent text-slate-500">
              <ArrowPathIcon className="h-5 w-5 animate-spin" />
            </Button>
          )}
          {/* Hidden element for measuring text width */}
          {autoGrowWidth && (
            <span
              ref={measureRef}
              style={{
                position: "absolute",
                visibility: "hidden",
                height: "auto",
                width: "auto",
                whiteSpace: "pre",
              }}
            />
          )}
        </div>
      </div>
      {errorType && errorText && (
        <>
          {errorType === "danger" && (
            <div className="mt-1 flex items-center text-xs leading-none text-red-600">
              <ShieldExclamationIcon className="mr-2 h-4 w-4" />
              {errorText}
            </div>
          )}
          {errorType === "warning" && (
            <div className="mt-1 flex items-center text-xs leading-none text-amber-600">
              <ExclamationTriangleIcon className="mr-2 h-4 w-4" />
              {errorText}
            </div>
          )}
          {errorType === "success" && (
            <div className="mt-1 flex items-center text-xs leading-none text-green-600">
              <ExclamationCircleIcon className="mr-2 h-4 w-4" />
              {errorText}
            </div>
          )}
          {errorType === "info" && (
            <div className="mt-1 flex items-center text-xs leading-none text-sky-600">
              <InformationCircleIcon className="mr-2 h-4 w-4" />
              {errorText}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Input;
