import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageStripeCredential } from "src/actions/site";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import { classNames } from "src/helpers/classNames";

const StripeSettings = ({ site, ...props }) => {
  const [stripePublishableKey, setStripePublishableKey] = useState("");
  const [stripeSecretKey, setStripeSecretKey] = useState("");
  const [stripeCurrency, setStripeCurrency] = useState("usd");
  const [isDisabled, setIsDisabled] = useState(false);

  const resetInput = () => {
    setStripePublishableKey("");
    setStripeSecretKey("");
    setStripeCurrency("usd");
  };

  const handleStripeCredential = async () => {
    try {
      setIsDisabled(true);
      const message = await props.manageStripeCredential({ stripe_publishable_key: stripePublishableKey, stripe_secret_key: stripeSecretKey, stripe_currency: stripeCurrency });
      toast.success(message);
      resetInput();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <EditContainer
      title="Stripe Settings"
      preview={
        <div className="flex h-10 w-56 items-center">
          <p className={classNames("flex gap-x-2 rounded px-2 py-1 opacity-60", !site?.stripe_publishable_key && site?.stripe_secret_key ? "bg-gray-50" : "bg-green-50 text-green-700")}>
            {!site?.stripe_publishable_key && site?.stripe_secret_key ? (
              <>
                <NoSymbolIcon className="h-5 w-5" /> Disabled
              </>
            ) : (
              <>
                <CheckIcon className="h-5 w-5" /> Enabled
              </>
            )}
          </p>
        </div>
      }
      defaultOpen={true}
      isLoading={isDisabled}
      onSuccess={handleStripeCredential}
      onCancel={resetInput}>
      <div className="flex w-full flex-wrap gap-x-4 gap-y-6">
        <div className="min-w-[260px] px-2">
          <div className="flex w-full flex-wrap gap-x-4 gap-y-6">
            <div className="w-full px-2">
              <Input
                type="password"
                name="stripe-publishable_key"
                label="Publishable key"
                inline={true}
                value={stripePublishableKey}
                placeholder={site?.stripe_publishable_key}
                onChange={(e) => setStripePublishableKey(e.target.value)}
              />
            </div>
            <div className="w-full px-2">
              <Input
                type="password"
                name="stripe-secret_key"
                label="Secret key"
                inline={true}
                value={stripeSecretKey}
                placeholder={site?.stripe_secret_key}
                onChange={(e) => setStripeSecretKey(e.target.value)}
              />
            </div>
            <div className="w-full px-2">
              <Input
                name="stripe-currency"
                label="Currency"
                inline={true}
                value={stripeCurrency}
                onChange={(e) => setStripeCurrency(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageStripeCredential })(StripeSettings);
