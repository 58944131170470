import { ArrowPathIcon, Square2StackIcon, TrashIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSharedSmtpSettings, getSharedSmtpDomainDetails, verifySharedSmtpDomain, manageSiteSettings, deleteSharedSmtpDomain } from "src/actions/site";
import { deleteWorkspaceSharedSmtpSettings, getWorkspaceSharedSmtpDomainDetails, manageWorkspaceSetSharedSmtpSettings, manageWorkspaceSharedSmtpSettings, manageWorkspaceVerifySharedSmtpSettings } from "src/actions/workspace";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Checkbox from "src/components/Shared/Forms/Inputs/Checkbox";
import Input from "src/components/Shared/Forms/Inputs/Input";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import { H3 } from "src/components/Shared/Text/Headers";
import { classNames } from "src/helpers/classNames";
import TestSmtpSettings from "./TestSmtpSettings";
import Modal from "src/components/Shared/Modal";
import validator from "validator";

const DnsItem = ({ item, domainDetails }) => (
  <div className="flex border border-gray-200">
    <div className={classNames("flex w-36 flex-shrink-0 items-center px-4 py-4 ")}>
      {" "}
      <div className={classNames("rounded-md px-2 pb-1 pt-1.5 text-xs !leading-none", item?.valid === "valid" ? "bg-green-100 text-green-700/50" : "bg-gray-100 text-gray-400")}>{item?.valid === "valid" ? "Verified" : "Unverified"}</div>
    </div>
    <div className="flex w-36 flex-shrink-0 items-center px-4 py-4 text-sm text-gray-700">{item?.record_type}</div>
    <div className="flex w-1/2 max-w-[calc(50%-4.5rem)] flex-shrink items-center px-4 py-4">
      <div className="flex-shrink flex-grow whitespace-pre-wrap break-all text-sm text-gray-700">{item?.name || domainDetails?.name}</div>
      <CopyToClipboard
        text={item?.name || domainDetails?.name}
        onCopy={() => toast.success("Copied to clipboard")}>
        <Button
          type="button"
          version="default"
          className="cursor-pointer">
          <Square2StackIcon className="h-10 w-10 p-2 text-gray-500 hover:text-highlightColor" />
        </Button>
      </CopyToClipboard>
    </div>
    <div className="flex w-1/2 max-w-[calc(50%-4.5rem)] flex-shrink items-center px-4 py-4">
      <div className="flex-shrink flex-grow whitespace-pre-wrap break-all text-sm text-gray-700">{item?.value}</div>
      <CopyToClipboard
        text={item?.value}
        onCopy={() => toast.success("Copied to clipboard")}>
        <Button
          type="button"
          version="default"
          className="cursor-pointer">
          <Square2StackIcon className="h-10 w-10 p-2 text-gray-500 hover:text-highlightColor" />
        </Button>
      </CopyToClipboard>
    </div>
    <div className="flex w-36 flex-shrink-0 items-center px-4 py-4 text-sm text-gray-700">{item?.priority ? item?.priority : ""}</div>
  </div>
);

const DomainDelete = ({ settings, onDeleteDomainModalOpen }) => (
  <div className="mb-6 flex">
    <div className="flex items-center gap-4 rounded-lg border border-gray-200 px-4 py-2 shadow-md">
      <div className="flex-shrink flex-grow">
        <div className="flex flex-row items-center justify-start gap-4 text-gray-700">
          Email: <div className="text-sm text-gray-500">{settings?.smtp_shared_email}</div>
        </div>
        <div className="flex flex-row items-center justify-start gap-4 text-gray-700">
          Domain: <div className="text-sm text-gray-500">{settings?.smtp_domain}</div>
        </div>
      </div>
      <div className="flex-shrink-0">
        <Button
          type="button"
          onClick={onDeleteDomainModalOpen}
          version="secondary">
          <TrashIcon className="h-5 w-5" />
        </Button>
      </div>
    </div>
  </div>
);

const DnsList = ({ settings, dnsList, domainDetails, smtpDomainVerify, setSmtpDomainVerify, isLoading, onVerify, onDeleteDomainModalOpen }) => (
  <div className="-mx-2 sm:-mx-4">
    <div className="mb-2">
      <span className="inline-flex items-center justify-center rounded-md bg-green-100 px-2 pb-[3px] pt-[2px] text-green-600">Step 2</span>
    </div>
    <H3
      className="!text-base"
      caption="You will need to install the following records to complete the process.">
      Install DNS Records
    </H3>
    <DomainDelete
      settings={settings}
      onDeleteDomainModalOpen={onDeleteDomainModalOpen}
    />
    <div className="space-y-4">
      <div className="space-y-2">
        <div className="flex items-center gap-4">
          <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border border-gray-300 text-sm font-semibold">1</div>
          <div className="text-sm font-bold text-gray-600">Install and verify all DNS records listed below.</div>
        </div>
        <div className="w-full pl-12">
          <div className="flex">
            <div className="flex w-36 flex-shrink-0 items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Status</div>
            <div className="flex w-36 flex-shrink-0 items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Type</div>
            <div className="flex w-1/2 flex-shrink items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Host</div>
            <div className="flex w-1/2 flex-shrink items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Points To</div>
            <div className="flex w-36 flex-shrink-0 items-center px-4 py-4 text-xs font-semibold uppercase text-gray-400">Priority</div>
          </div>
          <div className="-space-y-px">
            {dnsList?.length > 0 &&
              dnsList?.map((item, index) => (
                <DnsItem
                  key={index}
                  item={item}
                  domainDetails={domainDetails}
                />
              ))}
          </div>
        </div>
      </div>
      <div className="space-y-2">
        <div className="flex items-center gap-4">
          <div className="flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border border-gray-300 text-sm font-semibold">2</div>
          <div className="text-sm font-bold text-gray-600">Confirm you've added these once completed.</div>
        </div>
        <div className="w-full pl-12">
          <Checkbox
            isChecked={smtpDomainVerify}
            onChange={(e) => setSmtpDomainVerify(e.target.checked)}
            checkboxLabel={"I've added these records."}
          />
          <div className="ml-auto flex flex-shrink-0 gap-x-4 pt-6">
            <Button
              disabled={!smtpDomainVerify || isLoading}
              onClick={onVerify}
              type="submit">
              Verify{isLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const DomainInput = ({ smtpSharedEmail, setSmtpSharedEmail, setIsDisabled, isDisabled, onClear, onSubmit, isLoading }) => (
  <>
    {/* <H3 caption="Enter your domain and edit any additional settings.">Authenticate your domain</H3> */}
    <div className="grid space-y-4 sm:space-y-8">
      <div className="flex w-full flex-wrap justify-between">
        <div className="flex w-full flex-wrap gap-y-6">
          <div className="-mx-2 sm:-mx-4">
            <div className="mb-4">
              <span className="mr-1 inline-flex items-center justify-center rounded-md bg-green-100 px-2 pb-[3px] pt-[2px] text-green-600">Step 1</span>
              <div className="pt-2">
                <p className="text-gray-800">Decide on an email address you'd like your emails to be sent from.</p>
                <p className="text-sm text-gray-400">Based on the email address we will create the correct DNS credentials for you.</p>
              </div>
            </div>
            <div className="flex w-full gap-x-2">
              <Input
                label="SMTP Email"
                value={smtpSharedEmail}
                onChange={(e) => {
                  setSmtpSharedEmail(e.target.value);
                  setIsDisabled(false);
                }}
                inline={true}
              />{" "}
              <Button
                disabled={isDisabled || !validator.isEmail(smtpSharedEmail)}
                onClick={onSubmit}
                type="submit">
                Submit{isLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const SetDefaultSmtp = ({ settings, workspaceId, defaultSharedSmtp, setDefaultSharedSmtp, setIsDisabled, isDisabled, onClear, onDefaultSharedSmtp, isLoading, onDeleteDomainModalOpen }) => (
  <div className="space-y-4">
    <H3 caption="Set this one as a default SMTP.">Set as default SMTP</H3>
    <DomainDelete
      settings={settings}
      onDeleteDomainModalOpen={onDeleteDomainModalOpen}
    />
    <div className="w-full space-y-4">
      <Checkbox
        isChecked={defaultSharedSmtp}
        onChange={(e) => {
          setDefaultSharedSmtp(e.target.checked);
          setIsDisabled(false);
        }}
        checkboxLabel={"Use this as default SMTP."}
      />
      <div className="flex w-full flex-col items-start justify-between gap-4 sm:flex-row">
        <TestSmtpSettings workspaceId={workspaceId} />
        <div className="flex flex-shrink-0 justify-between gap-x-4 pt-2">
          <Button
            version="gray"
            disabled={isDisabled}
            onClick={onClear}>
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            onClick={onDefaultSharedSmtp}
            type="submit">
            Save{isLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
          </Button>
        </div>
      </div>
    </div>
  </div>
);

const ShareSmtpSettings = ({ manageSharedSmtpSettings, manageSiteSettings, verifySharedSmtpDomain, deleteSharedSmtpDomain, workspaceId = null }) => {
  const [smtpSharedEmail, setSmtpSharedEmail] = useState(null);
  const [smtpDomainVerify, setSmtpDomainVerify] = useState(false);
  const [defaultSharedSmtp, setDefaultSharedSmtp] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDomainDetailsLoaded, setIsDomainDetailsLoaded] = useState(false);
  const [domainDetails, setDomainDetails] = useState(null);
  const [dnsList, setDnsList] = useState([]);
  const [settings, setSettings] = useState(null);
  const [isDeleteDomainModalOpen, setIsDeleteDomainModalOpen] = useState(false);
  const [isDeleteDomain, setIsDeleteDomain] = useState(false);

  const setDefaultValue = (data) => {
    setSmtpSharedEmail(data?.smtp_shared_email || "");
    setSmtpDomainVerify(data?.smtp_domain_verify || false);
    setDefaultSharedSmtp(data?.default_shared_smtp || false);
  };

  const onClear = () => {
    setDefaultValue(settings);
    setIsDisabled(true);
  };

  const getDomainDetails = async (from = "") => {
    try {
      if (from !== "onVerify") setIsDomainDetailsLoaded(false);
      let data;
      if (workspaceId) {
        data = await getWorkspaceSharedSmtpDomainDetails({ workspace_id: workspaceId });
      } else {
        data = await getSharedSmtpDomainDetails();
      }
      setDefaultValue(data?.settings);
      setSettings(data?.settings);
      setDomainDetails(data?.domain);
      setDnsList([...(data?.sending_dns_records || []), ...(data?.receiving_dns_records || [])]);
      setIsDomainDetailsLoaded(true);
    } catch (error) {
      if (error.message !== "CanceledError: canceled") {
        setIsDomainDetailsLoaded(true);
      }
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      setIsDisabled(true);

      let message;
      if (workspaceId) {
        message = await manageWorkspaceSharedSmtpSettings({ workspace_id: workspaceId, smtp_shared_email: smtpSharedEmail });
      } else {
        message = await manageSharedSmtpSettings({ smtp_shared_email: smtpSharedEmail });
      }
      getDomainDetails();
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
      setIsDisabled(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onDefaultSharedSmtp = async () => {
    try {
      setIsLoading(true);
      setIsDisabled(true);

      let message;
      if (workspaceId) {
        message = await manageWorkspaceSetSharedSmtpSettings({ workspace_id: workspaceId, default_shared_smtp: defaultSharedSmtp });
      } else {
        message = await manageSiteSettings({ default_shared_smtp: defaultSharedSmtp }, "/settings/smtp/shared/default");
      }
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
      setIsDisabled(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onVerify = async () => {
    try {
      setIsLoading(true);

      let message;
      if (workspaceId) {
        message = await manageWorkspaceVerifySharedSmtpSettings({ workspace_id: workspaceId, smtp_domain_verify: smtpDomainVerify });
      } else {
        message = await verifySharedSmtpDomain({ smtp_domain_verify: smtpDomainVerify });
      }
      getDomainDetails();
      toast.success(message);
    } catch (error) {
      getDomainDetails("onVerify");
      setSmtpDomainVerify(false);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteDomainModalOpen = () => {
    setIsDeleteDomainModalOpen(true);
  };

  const onDeleteDomain = async () => {
    try {
      setIsDeleteDomain(true);

      let message;
      if (workspaceId) {
        message = await deleteWorkspaceSharedSmtpSettings({ workspace_id: workspaceId });
      } else {
        message = await deleteSharedSmtpDomain();
      }
      setIsDeleteDomainModalOpen(false);
      getDomainDetails();
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleteDomain(false);
    }
  };

  useEffect(() => {
    getDomainDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceId]);

  return (
    <Section>
      <div className="grid gap-y-8">
        {isDomainDetailsLoaded ? (
          settings?.smtp_shared_email ? (
            settings?.smtp_domain_verify ? (
              <SetDefaultSmtp
                settings={settings}
                workspaceId={workspaceId}
                defaultSharedSmtp={defaultSharedSmtp}
                setDefaultSharedSmtp={setDefaultSharedSmtp}
                setIsDisabled={setIsDisabled}
                isDisabled={isDisabled}
                onClear={onClear}
                onDefaultSharedSmtp={onDefaultSharedSmtp}
                isLoading={isLoading}
                onDeleteDomainModalOpen={onDeleteDomainModalOpen}
              />
            ) : (
              <DnsList
                settings={settings}
                dnsList={dnsList}
                domainDetails={domainDetails}
                smtpDomainVerify={smtpDomainVerify}
                setSmtpDomainVerify={setSmtpDomainVerify}
                isLoading={isLoading}
                onVerify={onVerify}
                onDeleteDomainModalOpen={onDeleteDomainModalOpen}
              />
            )
          ) : (
            <DomainInput
              smtpSharedEmail={smtpSharedEmail}
              setSmtpSharedEmail={setSmtpSharedEmail}
              setIsDisabled={setIsDisabled}
              isDisabled={isDisabled}
              onClear={onClear}
              onSubmit={onSubmit}
              isLoading={isLoading}
            />
          )
        ) : (
          <Preloader />
        )}
      </div>
      <Modal
        title="Domain"
        secondaryTitle="Delete"
        isOpen={isDeleteDomainModalOpen}
        onCancel={() => setIsDeleteDomainModalOpen(false)}
        isLoading={isDeleteDomain}
        onSuccess={onDeleteDomain}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="flex flex-col gap-y-1 whitespace-nowrap px-3 py-6 text-gray-600">
          <p className="text-lg">
            Are you sure you want to <span className="px-1 font-semibold text-gray-700">DELETE</span>
            this domain?
          </p>
          <div className="text-gray-400">Once you delete this domain it's gone for good.</div>
        </div>
      </Modal>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, { manageSharedSmtpSettings, manageSiteSettings, verifySharedSmtpDomain, deleteSharedSmtpDomain })(ShareSmtpSettings);
