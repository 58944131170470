import { PencilIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import AutoPopulate from "src/components/Shared/Forms/AutoPopulate";
import SingleLineCheckbox from "src/components/Shared/Forms/Inputs/SingleLineCheckbox";
import MultiSelectDropdown from "src/components/Shared/Forms/Selects/MultiSelectDropdown";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import { useDomo } from "src/context/DomoProvider";
import { v4 } from "uuid";
import NestedPageContent from "./NestedPageContent";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";

const DomoPage = ({ newPage = {}, setNewPage = () => {}, startIndex = -1, pageCredentialOptions = {}, nestedAllPages = [], reorderNestedPages = () => {}, workspaceDetails = null, workspaceId = null, platformEmbeds = [] }) => {
  const [credMenuDisabled, setCredMenuDisabled] = useState(true);
  const [embedTypes, setEmbedTypes] = useState([
    { key: "Dashboard", value: "EMBED_PAGE" },
    { key: "Card", value: "EMBED_CARD" },
    { key: "App", value: "EMBED_APP" },
    // { key: "Nested Pages", value: "NESTED_EMBED" }, // Remove for now since the new App Studio has been released
  ]);
  const [isEmbedIdLoaderStart, setIsEmbedIdLoaderStart] = useState(false);
  const [isDatasetColumnLoaderStart, setIsDatasetColumnLoaderStart] = useState(false);
  const [embedCodes, setEmbedCodes] = useState([]);
  const [columnNames, setColumnNames] = useState([]);
  const { getEmbedIdCodesByCredential, getColumnsNameByEmbedIdAndCredentialId } = useDomo();

  useEffect(() => {
    setEmbedTypes((prevData) => {
      if (platformEmbeds?.length > 0) {
        const newEntry = { key: "Platform", value: "EMBED_PLATFORM" };
        const isDuplicate = prevData.find((entry) => entry.value === newEntry.value);
        if (!isDuplicate) {
          return [...prevData, newEntry];
        }
        return prevData;
      } else {
        return prevData.filter((data) => data?.value !== "EMBED_PLATFORM");
      }
    });
  }, [platformEmbeds]);

  useEffect(() => {
    if (!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)) {
      setNewPage((prevData) => ({
        ...prevData,
        credential_id: pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage?._id, pageCredentialOptions]);

  useEffect(() => {
    if (!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default)?.value === newPage?.credential_id) {
      setCredMenuDisabled(true);
    }
  }, [newPage?._id, pageCredentialOptions, newPage?.credential_id]);

  const getEmbedCodes = async () => {
    try {
      setIsEmbedIdLoaderStart(true);
      const codes = await getEmbedIdCodesByCredential({ workspaceId, credentialId: newPage?.credential_id });
      setEmbedCodes(codes);
      setIsEmbedIdLoaderStart(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (newPage?.credential_id) getEmbedCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage?.credential_id]);

  const getDatasetColumns = async () => {
    try {
      setIsDatasetColumnLoaderStart(true);
      const columns = await getColumnsNameByEmbedIdAndCredentialId({ workspaceId, credentialId: newPage?.credential_id, embedId: newPage?.embed_id });
      const previousColumnName = newPage?.columns?.map((item) => item?.name);
      setNewPage((prevData) => ({ ...prevData, columns: columns?.filter((item) => previousColumnName?.includes(item?.name)) }));
      setColumnNames(columns);
      setIsDatasetColumnLoaderStart(false);
    } catch (error) {}
  };

  useEffect(() => {
    if (newPage?.credential_id && newPage?.embed_id && newPage?.embed_id?.length === 5) {
      getDatasetColumns();
    }
    setColumnNames([]);
    setNewPage({ ...newPage, columns: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPage?.credential_id, newPage?.embed_id]);

  return (
    <div>
      <div className="w-full space-y-4">
        <div className="flex w-full flex-col gap-x-3 gap-y-3 sm:flex-row">
          <div className="flex-shrink-0 flex-grow-0 sm:w-1/3">
            {/* Embed Type */}
            <SelectMenu
              label="Embed Type"
              inline={true}
              startIndex={embedTypes.findIndex((item) => item.value === newPage.embed_type)}
              options={embedTypes}
              dropdownClass={"!min-w-0 w-full"}
              setOption={(option) => {
                setNewPage((prevData) => ({
                  ...prevData,
                  embed_type: option.value,
                  embed_id: option.value === "EMBED_PLATFORM" ? "" : prevData?.embed_id,
                  credential_id: option.value === "EMBED_PLATFORM" ? null : prevData?.credential_id,
                  platform_embed: option.value === "EMBED_PLATFORM" ? prevData?.platform_embed : null,
                  columns: option.value === "EMBED_PLATFORM" ? [] : prevData?.columns,
                  cache_select_columns: option.value === "EMBED_PLATFORM" ? false : prevData?.cache_select_columns,
                }));
                if (option.value === "EMBED_PLATFORM") {
                  setEmbedCodes([]);
                  setColumnNames([]);
                }
              }}
            />
          </div>
          {/* Credentials or platform page */}
          <div className="flex-shrink flex-grow sm:w-2/3 sm:max-w-[calc(66.67%-10px)]">
            {newPage?.embed_type !== "EMBED_PLATFORM" ? (
              <div className="relative">
                <SelectMenu
                  inline={true}
                  disabled={(!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default) && credMenuDisabled) || (newPage?._id && pageCredentialOptions.length === 0 && newPage?.credential_name)}
                  label="DOMO credentials"
                  emptyListText={newPage?.credential_name || "No listed items"}
                  defaultText={newPage?.credential_name || "Select an option"}
                  startIndex={startIndex}
                  options={pageCredentialOptions || [{ key: "Select credentials", value: -1 }]}
                  selectedOptionRendering="key"
                  setOption={(option) => {
                    setNewPage({ ...newPage, credential_id: option.value });
                  }}
                />
                {!newPage?._id && pageCredentialOptions.find((pageCredentialOption) => pageCredentialOption?.default) && credMenuDisabled && (
                  <div className="absolute bottom-0.5 right-0.5 z-30 flex aspect-square w-9 items-center justify-center rounded-md bg-white">
                    <Button
                      version="default"
                      className="!h-6"
                      onClick={() => setCredMenuDisabled(false)}>
                      <PencilIcon className="h-4 w-4 text-gray-400" />
                    </Button>
                  </div>
                )}
              </div>
            ) : (
              <div className="relative">
                <SelectMenu
                  inline={true}
                  label="Platform Embeds"
                  startIndex={platformEmbeds.findIndex((item) => item._id === newPage.platform_embed)}
                  options={platformEmbeds?.map((item) => ({ key: item?.name, value: item?._id })) || [{ key: "Select platform", value: -1 }]}
                  setOption={(option) => {
                    setNewPage({ ...newPage, platform_embed: option.value });
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {newPage?.embed_type !== "EMBED_PLATFORM" && (
          <div className="w-full">
            <div className="flex w-full flex-col gap-3 sm:flex-row">
              <div className="flex w-full flex-shrink flex-grow rounded-md">
                <div className=" flex-shrink flex-grow">
                  <AutoPopulate
                    isLoading={isEmbedIdLoaderStart}
                    loaderText={"Syncing from DOMO"}
                    placeholder={"Embed ID"}
                    defaultValue={newPage?.embed_id}
                    options={embedCodes.map((code) => ({
                      key: code?.embedId,
                      value: code?.embedId,
                      object: code,
                      Component: () => {
                        return (
                          <div className="flex items-center gap-x-3">
                            <div className="flex items-center truncate text-sm">
                              {code.embedId}
                              <span className="truncate text-xs text-gray-300"> : {code.name}</span>
                            </div>
                          </div>
                        );
                      },
                    }))}
                    searchableFields={["name", "embedId"]}
                    onClick={(data) => {
                      setNewPage({ ...newPage, embed_id: data.value });
                    }}
                    onChange={(data) => {
                      setNewPage({ ...newPage, embed_id: data });
                    }}
                  />
                </div>
              </div>
            </div>
            {isDatasetColumnLoaderStart ? (
              <div className="mt-2 flex w-full">
                <Preloader
                  text="Checking"
                  size="sm"
                  circleDimension={"4"}
                  className={"!m-0 !gap-x-2 !px-0 !py-2"}
                />
              </div>
            ) : (
              columnNames?.length > 0 && (
                <div className="mt-2 w-full">
                  <SingleLineCheckbox
                    disabled={newPage?.credential_id && newPage?.embed_id ? false : true}
                    value={newPage?.cache_select_columns}
                    onChange={(e) => setNewPage((prevData) => ({ ...prevData, cache_select_columns: e, columns: e ? prevData?.columns : [] }))}
                    title=""
                    subtitle="Would you like to cache the columns ?"
                  />
                </div>
              )
            )}
            {newPage?.cache_select_columns && columnNames?.length > 0 && (
              <div className="mt-2 w-full">
                <MultiSelectDropdown
                  isLoading={isDatasetColumnLoaderStart}
                  checkboxPosition="left"
                  searchableFields={["name"]}
                  placeholder="Dataset Columns"
                  inline={true}
                  selectedItem={newPage?.columns?.map((item) => item?.name)}
                  dropdownData={columnNames.map((columnName) => ({
                    _id: columnName?.name,
                    name: columnName?.name,
                    object: columnName,
                  }))}
                  onClick={(event, item) => {
                    if (event.target.checked) {
                      setNewPage((prevData) => ({ ...prevData, columns: [...(prevData?.columns || []), { ...item.object }] }));
                    } else {
                      setNewPage((prevData) => ({ ...prevData, columns: prevData?.columns?.filter((data) => data?.name !== item._id) }));
                    }
                  }}
                  isSelectAll={true}
                  selectAll={() => {
                    if (columnNames?.length === newPage?.columns?.length) {
                      setNewPage((prevData) => ({ ...prevData, columns: [] }));
                    } else {
                      setNewPage((prevData) => ({ ...prevData, columns: columnNames }));
                    }
                  }}
                  dropdownClass={"!w-1/2"}
                  scrollableContainerClass={"!max-h-40"}
                />
              </div>
            )}
          </div>
        )}
      </div>

      {newPage.embed_type === "NESTED_EMBED" && <hr className="my-6" />}
      {newPage.embed_type === "NESTED_EMBED" && (
        <NestedPageContent
          newPage={{
            ...newPage,
            nested_pages: !workspaceDetails
              ? newPage.nested_pages.filter((item) => (item.page_id !== null ? nestedAllPages.map((page) => page._id).includes(item.page_id) : item !== null)).map((item) => ({ id: v4(), ...item }))
              : newPage.nested_pages.filter((item) => (item.page_id !== null ? (workspaceDetails.pages || []).map((page) => page.page_id).includes(item.page_id) : item !== null)).map((item) => ({ id: v4(), ...item })),
          }}
          pages={nestedAllPages}
          onDragEnd={(result) => {
            if (!result.destination) {
              return;
            }

            reorderNestedPages(newPage.nested_pages, result.source.index, result.destination.index);
          }}
          onAddPage={() =>
            setNewPage((page) => ({
              ...page,
              nested_pages: [...page.nested_pages, { id: v4(), page_id: null, ordering: page.nested_pages.length }],
            }))
          }
          onUpdatePage={(data, index) => {
            setNewPage((page) => ({
              ...page,
              nested_pages: page.nested_pages.map((page, i) =>
                i !== index
                  ? page
                  : {
                      page_id: data.page_id,
                      ordering: i,
                    },
              ),
            }));
          }}
          onRemovePage={(index) =>
            setNewPage((page) => ({
              ...page,
              nested_pages: page.nested_pages.filter((page, i) => i !== index),
            }))
          }
        />
      )}
    </div>
  );
};

export default DomoPage;
