import React from "react";
import { classNames } from "src/helpers/classNames";

export default function ToggleHeader({ title = "", subtitle = "", children, position = "left", titleClassName = "" }) {
  return (
    <div className="mb-4 flex font-medium text-gray-600">
      <div className={classNames("flex items-center gap-x-5", position === "right" ? "" : "flex-row-reverse")}>
        <div>
          <p className={classNames(titleClassName)}>{title}</p>
          <p className="text-sm text-gray-400">{subtitle}</p>
        </div>
        {children}
      </div>
    </div>
  );
}
