import { useEffect, useState } from "react";
import { classNames } from "src/helpers/classNames";
import Button from "../Buttons/Button";

const OptionTabs = ({ tabs = [], activeTab = "", tabDivClassName = "", tabButtonClassName = "", tabContentClassName = "" }) => {
  const [selectedTab, setSelectedTab] = useState({});

  useEffect(() => {
    if (tabs?.length > 0) setSelectedTab(tabs?.find((tab) => tab?.name === activeTab) || tabs[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, tabs?.length]);
  return (
    <>
      <div className={classNames("flex flex-wrap gap-6", tabDivClassName)}>
        {tabs?.length > 0 &&
          tabs?.map((tab, index) => (
            <Button
              key={index}
              version="default"
              className={classNames(
                "relative flex !h-auto w-full max-w-80 flex-col !items-start space-y-2 rounded-md border bg-white px-4 py-4 !pr-10 text-left",
                tab?.name === selectedTab?.name ? "border-transparent ring-2 ring-highlightColor ring-offset-2" : "border-gray-200",
                tabButtonClassName,
              )}
              onClick={() => setSelectedTab(tab)}>
              <div className="text-base font-semibold text-gray-800">{tab?.name}</div>
              {tab?.description && <div className="text-sm text-gray-400">{tab?.description}</div>}
              {tab?.isHighlighted && <div className="py-.5 absolute right-[7px] top-0 rounded-full border border-green-500 px-2 text-green-500">Active</div>}
            </Button>
          ))}
      </div>
      <div className={classNames("mt-4 w-full", tabContentClassName)}>{selectedTab?.component}</div>
    </>
  );
};

export default OptionTabs;
