import { XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import Button from "src/components/Shared/Buttons/Button";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import { FeatureFlags } from "src/context/FeatureFlagContext";
import { classNames } from "src/helpers/classNames";
import useEventListener from "src/hooks/useEventListener";

const ToggleFlags = ({ editFlags }) => {
  const [showMenu, setShowMenu] = useState(false);

  // Keyboard event handler to toggle the menu by pressing all the keys "Ctrl + Shift + O".
  function handler(e) {
    if (e.key?.toLowerCase(e.key) === "o" && e.ctrlKey && e.shiftKey) {
      setShowMenu(!showMenu); // Toggle the menu state.
    }
  }

  // Attach the keyboard event listener using a custom hook.
  useEventListener("keydown", handler);

  return (
    // Use FeatureFlags.Consumer to access feature flags from context.
    <FeatureFlags.Consumer>
      {(flags) => {
        return (
          <div
            // Side menu container with conditional class to slide in/out.
            className={classNames(
              "fixed -right-[300px] top-0 z-30 h-full w-[300px] border-l-[1px] border-gray-200 bg-white p-4 pt-14 shadow-lg transition-all duration-150",
              showMenu ? "right-[0px]" : "", // Show the menu when `showMenu` is true.
            )}>
            {/* Close button to toggle the menu off */}
            <Button
              type="button"
              version="default"
              className="absolute right-2 top-2 rounded-full bg-white p-1 text-red-400 hover:bg-gray-100 hover:text-red-500 focus:outline-none"
              onClick={() => setShowMenu(!showMenu)}>
              <span className="sr-only">Close</span> {/* Screen reader-only label */}
              <XMarkIcon
                className="h-6 w-6"
                aria-hidden="true"
              />{" "}
              {/* Icon for close */}
            </Button>

            {/* Container for feature flags */}
            <div className="relative h-full rounded-md border border-gray-200 pt-6">
              {/* Title for the feature flags section */}
              <div className="absolute -top-2.5 left-5 bg-white px-2 text-gray-400">Feature flags</div>

              {/* Render flags if the `flags` is an array */}
              {Array.isArray(flags) &&
                flags?.map((flag) => {
                  return (
                    // Render each flag with a toggle and its name.
                    <div
                      key={flag.name}
                      className="flex items-center space-x-2 rounded-full px-6 py-4">
                      {/* Toggle switch to update flag state */}
                      <Toggle
                        checked={flag?.active}
                        onChange={(e) => editFlags(e, flag.name)}
                      />
                      {/* Display the flag name */}
                      <span className="text-sm font-medium">{flag.name}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      }}
    </FeatureFlags.Consumer>
  );
};

export default ToggleFlags; // Export the component as the default export.
