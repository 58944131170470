import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { getSso, updateSsoWorkspaceGroupSettings, updateSsoCustomFieldSettings } from "src/actions/sso";
import { apiRequest } from "src/async/apiUtils";
import Button from "src/components/Shared/Buttons/Button";
import Input from "src/components/Shared/Forms/Inputs/Input";
import TextCode from "src/components/Shared/Text/TextCode";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import SubArrowIcon from "src/components/Shared/Icons/SubArrowIcon";
import { classNames } from "src/helpers/classNames";
import Section from "src/components/Shared/Containers/Section";
import NoDataDashedContainer from "src/components/Shared/NoData/NoDataDashedContainer";
import { v4 } from "uuid";

const SSOAttributeMapper = ({
  type = "content",
  text = "",
  value = "",
  mapAttributeDropdownData = [],
  includedDropdownData = [],
  includedIndex = 0,
  mapAttributeIndex = 0,
  customFieldAttributeDropdownData = [],
  customFieldAttributeIndex = 0,
  setValue = () => {},
  removeData = () => {},
  setIncludedOption = () => {},
  setMapAttributeOption = () => {},
  setCustomFieldAttributeOption = () => {},
}) => {
  return (
    <div className="flex w-full items-center">
      <SubArrowIcon className="flex-shrink-0" />
      <div className="relative flex min-h-14 flex-shrink flex-grow space-x-3 rounded border border-gray-200 pl-4">
        <div className="flex flex-shrink flex-grow flex-wrap items-center gap-x-3 gap-y-2 py-1.5">
          {type === "user" ? (
            <>
              <div className="relative flex flex-wrap items-center gap-2 text-sm font-medium text-gray-700 sm:flex-nowrap sm:gap-1">The incoming SSO user will have an attribute named</div>
              <div className="relative w-full sm:w-48">
                <Input
                  name={text}
                  value={value}
                  inputClassNames={"!h-10 border-gray-300 rounded-md focus:ring-0 focus:border-highlightColor"}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
              <div className="relative py-2.5 text-sm font-medium text-gray-700">
                that will be assign to the user's
                <div className="inline-flex px-2">
                  <SelectMenu
                    size="sm"
                    classes="!h-10"
                    options={customFieldAttributeDropdownData}
                    startIndex={customFieldAttributeIndex}
                    setOption={setCustomFieldAttributeOption}
                  />
                </div>
                field
              </div>
            </>
          ) : (
            <>
              <div className="relative flex flex-wrap items-center gap-2 text-sm font-medium text-gray-700 sm:flex-nowrap sm:gap-1">
                Add the incoming user to all
                {mapAttributeDropdownData.length > 1 ? (
                  <div className="px-2">
                    <SelectMenu
                      size="sm"
                      classes="!h-10"
                      options={mapAttributeDropdownData}
                      startIndex={mapAttributeIndex}
                      setOption={setMapAttributeOption}
                    />
                  </div>
                ) : (
                  <TextCode text={`${text}s`} />
                )}
                where the incoming SSO user attribute of
              </div>
              <div className="relative w-full sm:w-48">
                <Input
                  name={text}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  inputClassNames={"!h-10 w-full border-gray-300 rounded-md focus:ring-0 focus:border-highlightColor text-gray-700"}
                />
              </div>
              <div className="relative text-sm font-medium text-gray-700">
                is equal to an existing {text}'s
                {includedDropdownData.length > 1 ? (
                  <div className="ml-2 inline-flex">
                    <SelectMenu
                      size="sm"
                      classes="!h-10"
                      options={includedDropdownData}
                      startIndex={includedIndex}
                      setOption={setIncludedOption}
                    />
                  </div>
                ) : (
                  <TextCode text={`name`} />
                )}
              </div>
            </>
          )}
        </div>
        <div className="ml-auto flex-shrink-0 overflow-hidden rounded pl-6">
          <div
            onClick={removeData}
            className="flex min-h-full w-10 cursor-pointer items-center justify-center border-gray-200 bg-gray-50 text-gray-500 outline-none ring-0 transition-all duration-200 hover:bg-gray-100 hover:text-gray-600">
            <TrashIcon className="h-5 w-5" />
          </div>
        </div>
      </div>
    </div>
  );
};

const SSOServiceAttribute = ({ site, getSso, updateSsoWorkspaceGroupSettings, updateSsoCustomFieldSettings }) => {
  const { id } = useParams();
  const mapAttributeDropdownArray = [
    { value: "workspace", key: "workspaces" },
    { value: "group", key: "groups" },
  ];
  const workspaceIncludedDropdownArray = [
    { value: "tags", key: "tags" },
    { value: "name", key: "name" },
  ];
  const groupAttributeJson = {
    id: v4(),
    included_in: "name",
    trusted_attribute: "",
  };
  const workspaceGroupAttributeJson = {
    id: v4(),
    type: "workspace",
    included_in: "name",
    trusted_attribute: "",
    group_trusted_attribute: [],
  };
  const customFieldAttributeJson = {
    id: v4(),
    custom_field_id: null,
    value: "",
  };
  const [dynamicAttributes, setDynamicAttributes] = useState([]);
  const [prevWorkspaceGroupAttribute, setPrevWorkspaceGroupAttribute] = useState([]);
  const [workspaceGroupAttribute, setWorkspaceGroupAttribute] = useState([]);
  const [prevCustomFieldsAttribute, setPrevCustomFieldsAttribute] = useState([]);
  const [customFieldsAttribute, setCustomFieldsAttribute] = useState([]);
  const [workspaceGroupSubmitButtonDisabled, setWorkspaceGroupSubmitButtonDisabled] = useState(false);
  const [workspaceGroupUndoButtonDisabled, setWorkspaceGroupUndoButtonDisabled] = useState(true);
  const [customFieldSubmitButtonDisabled, setCustomFieldSubmitButtonDisabled] = useState(false);
  const [customFieldUndoButtonDisabled, setCustomFieldUndoButtonDisabled] = useState(true);

  useEffect(() => {
    const ac = new AbortController();
    const loadSSO = async () => {
      try {
        const data = await getSso({ id }, ac.signal);
        setWorkspaceGroupAttribute(data?.workspace_group_attribute || []);
        setPrevWorkspaceGroupAttribute(data?.workspace_group_attribute || []);
        setPrevCustomFieldsAttribute(data?.custom_fields_attribute || []);
      } catch (error) {
        // console.dir("ERROR:", error);
      }
    };
    if (id) {
      loadSSO();
    }

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDynamicAttributes = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200 && resData.data) {
          setDynamicAttributes(resData.data);
        }
      } catch (err) {
        console.dir("err", err);
      }
    })();
  }, []);

  const updateSSOWorkspaceGroup = async () => {
    try {
      setWorkspaceGroupUndoButtonDisabled(true);
      setWorkspaceGroupSubmitButtonDisabled(true);
      const message = await updateSsoWorkspaceGroupSettings({
        id: id,
        workspace_group_attribute: workspaceGroupAttribute,
      });
      setPrevWorkspaceGroupAttribute(workspaceGroupAttribute);
      toast.success(message);
      setWorkspaceGroupSubmitButtonDisabled(false);
    } catch (error) {
      setWorkspaceGroupUndoButtonDisabled(false);
      setWorkspaceGroupSubmitButtonDisabled(false);
      toast.error(error.message);
    }
  };

  const updateSSOCustomField = async () => {
    try {
      setCustomFieldUndoButtonDisabled(true);
      setCustomFieldSubmitButtonDisabled(true);
      const message = await updateSsoCustomFieldSettings({
        id: id,
        custom_fields_attribute: customFieldsAttribute,
      });
      setPrevCustomFieldsAttribute(customFieldsAttribute);
      toast.success(message);
      setCustomFieldSubmitButtonDisabled(false);
    } catch (error) {
      setCustomFieldUndoButtonDisabled(false);
      setCustomFieldSubmitButtonDisabled(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (prevCustomFieldsAttribute?.length > 0 && dynamicAttributes?.length > 0) {
      setCustomFieldsAttribute([
        ...prevCustomFieldsAttribute?.map((item) => ({
          ...item,
          dynamicAttributes,
        })),
      ]);
    }
  }, [prevCustomFieldsAttribute, dynamicAttributes]);

  useEffect(() => {
    loadDynamicAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClearWorkspaceGroup = () => {
    setWorkspaceGroupUndoButtonDisabled(true);
    setWorkspaceGroupAttribute(prevWorkspaceGroupAttribute);
  };

  const onClearCustomField = () => {
    setCustomFieldUndoButtonDisabled(true);
    setCustomFieldsAttribute([
      ...prevCustomFieldsAttribute?.map((item) => ({
        ...item,
        dynamicAttributes,
      })),
    ]);
  };

  return (
    <>
      <EditContainer
        title="Map SSO to Workspaces"
        preview={{
          text: workspaceGroupAttribute?.length ? (
            <>
              <div>{workspaceGroupAttribute?.length}</div>
              Mapped SSO attribute
              {workspaceGroupAttribute?.length > 1 && "s"}
            </>
          ) : (
            "No mapped SSO attributes"
          ),
        }}
        fullWidth={true}
        defaultOpen={true}
        onSuccess={updateSSOWorkspaceGroup}
        onCancel={onClearWorkspaceGroup}
        isDisabled={workspaceGroupSubmitButtonDisabled || workspaceGroupUndoButtonDisabled}
        isLoading={workspaceGroupSubmitButtonDisabled}>
        <Section
          shadow={true}
          className="mb-4 w-full gap-y-3 pt-10">
          {workspaceGroupAttribute?.length === 0 ? (
            <NoDataDashedContainer
              addButton={
                <Button
                  version="secondary"
                  hoverText="Map attribute to role"
                  onClick={() => {
                    setWorkspaceGroupUndoButtonDisabled(false);
                    setWorkspaceGroupAttribute((prevData) => [...prevData, workspaceGroupAttributeJson]);
                  }}>
                  <PlusIcon className="h-4 w-4 stroke-2" /> Add SSO attribute
                </Button>
              }
            />
          ) : (
            <div className="space-y-4">
              {workspaceGroupAttribute.map((item, index) => (
                <div
                  key={item?._id || item?.id}
                  className="flex gap-y-2">
                  <div className="flex w-full flex-col">
                    <div className="relative flex h-full w-full items-center">
                      <SSOAttributeMapper
                        value={item?.trusted_attribute}
                        setValue={(value) => {
                          setWorkspaceGroupUndoButtonDisabled(false);
                          setWorkspaceGroupAttribute((prevData) => [
                            ...prevData.map((data, prevIndex) =>
                              prevIndex === index
                                ? {
                                    ...data,
                                    trusted_attribute: value,
                                  }
                                : data,
                            ),
                          ]);
                        }}
                        text={item?.type}
                        includedDropdownData={item?.type === "workspace" ? workspaceIncludedDropdownArray : []}
                        includedIndex={workspaceIncludedDropdownArray.findIndex((includedItem) => includedItem?.value === item?.included_in) || 0}
                        setIncludedOption={(option) => {
                          setWorkspaceGroupUndoButtonDisabled(false);
                          setWorkspaceGroupAttribute((prevData) => [
                            ...prevData.map((data, prevIndex) =>
                              prevIndex === index
                                ? {
                                    ...data,
                                    included_in: option.value,
                                  }
                                : data,
                            ),
                          ]);
                        }}
                        mapAttributeDropdownData={mapAttributeDropdownArray}
                        mapAttributeIndex={mapAttributeDropdownArray.findIndex((attributeItem) => attributeItem?.value === item?.type) || 0}
                        setMapAttributeOption={(option) => {
                          setWorkspaceGroupUndoButtonDisabled(false);
                          setWorkspaceGroupAttribute((prevData) => [
                            ...prevData.map((data, prevIndex) =>
                              prevIndex === index
                                ? {
                                    ...data,
                                    type: option.value,
                                    group_trusted_attribute: option.value === "workspace" ? data.group_trusted_attribute : [],
                                    included_in: option.value === "workspace" ? data.included_in : "name",
                                  }
                                : data,
                            ),
                          ]);
                        }}
                        removeData={() => {
                          setWorkspaceGroupUndoButtonDisabled(false);
                          setWorkspaceGroupAttribute((prevData) => [...prevData.filter((_, prevIndex) => prevIndex !== index)]);
                        }}
                      />
                    </div>
                    <div className="flex flex-col justify-end pl-8">
                      <div className="relative flex flex-col gap-y-2 pl-1 pt-2">
                        {item?.type === "workspace" &&
                          item?.group_trusted_attribute?.length > 0 &&
                          item?.group_trusted_attribute?.map((subItem, subIndex) => (
                            <div
                              className="flex items-center"
                              key={subItem?._id || subItem?.id}>
                              <SSOAttributeMapper
                                value={subItem?.trusted_attribute}
                                subgroup={true}
                                setValue={(value) => {
                                  setWorkspaceGroupUndoButtonDisabled(false);
                                  setWorkspaceGroupAttribute((prevData) => [
                                    ...prevData.map((data, prevIndex) =>
                                      prevIndex === index
                                        ? {
                                            ...data,
                                            group_trusted_attribute: [
                                              ...data.group_trusted_attribute.map((subData, subPrevindex) =>
                                                subIndex === subPrevindex
                                                  ? {
                                                      ...subData,
                                                      trusted_attribute: value,
                                                    }
                                                  : subData,
                                              ),
                                            ],
                                          }
                                        : data,
                                    ),
                                  ]);
                                }}
                                text="group"
                                removeData={() => {
                                  setWorkspaceGroupUndoButtonDisabled(false);
                                  setWorkspaceGroupAttribute((prevData) => [
                                    ...prevData.map((data, prevIndex) =>
                                      prevIndex === index
                                        ? {
                                            ...data,
                                            group_trusted_attribute: [...data.group_trusted_attribute.filter((_, subPrevIndex) => subPrevIndex !== subIndex)],
                                          }
                                        : data,
                                    ),
                                  ]);
                                }}
                              />
                            </div>
                          ))}
                        {item?.type === "workspace" && (
                          <div className={classNames("flex items-center justify-start", item?.group_trusted_attribute?.length > 0 ? "pl-9" : "")}>
                            <Button
                              version="secondary"
                              hoverText="Add group mapping"
                              onClick={() => {
                                setWorkspaceGroupUndoButtonDisabled(false);
                                setWorkspaceGroupAttribute((prevData) => [
                                  ...prevData.map((data, prevIndex) =>
                                    prevIndex === index
                                      ? {
                                          ...data,
                                          group_trusted_attribute: [...data.group_trusted_attribute, groupAttributeJson],
                                        }
                                      : data,
                                  ),
                                ]);
                              }}>
                              <PlusIcon className="h-4 w-4 stroke-2" />
                              Add group
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="flex w-full items-center justify-end space-x-4 pr-4 pt-6">
                <Button
                  version="secondary"
                  hoverText="Map attribute to role"
                  onClick={() => {
                    setWorkspaceGroupUndoButtonDisabled(false);
                    setWorkspaceGroupAttribute((prevData) => [...prevData, workspaceGroupAttributeJson]);
                  }}>
                  <PlusIcon className="h-4 w-4 stroke-2" /> Add SSO attribute
                </Button>
              </div>
            </div>
          )}
        </Section>
      </EditContainer>
      <EditContainer
        title="Map SSO to User fields"
        borderBottom={false}
        preview={{
          text: customFieldsAttribute?.length ? (
            <>
              <div>{customFieldsAttribute?.length}</div>
              Mapped SSO User fields
              {customFieldsAttribute?.length > 1 && "s"}
            </>
          ) : (
            "No mapped SSO User fields"
          ),
        }}
        fullWidth={true}
        defaultOpen={true}
        onSuccess={updateSSOCustomField}
        onCancel={onClearCustomField}
        isDisabled={customFieldSubmitButtonDisabled || customFieldUndoButtonDisabled}
        isLoading={customFieldSubmitButtonDisabled}>
        <Section
          shadow={true}
          className="mb-4 w-full gap-y-3 pt-10">
          <>
            {customFieldsAttribute?.length === 0 ? (
              <div>
                <NoDataDashedContainer
                  addButton={
                    <Button
                      disabled={!dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))?.length}
                      version="secondary"
                      hoverText="Map attribute to user attribute"
                      onClick={() => {
                        setCustomFieldUndoButtonDisabled(false);
                        setCustomFieldsAttribute((prevData) => [
                          ...prevData,
                          {
                            ...customFieldAttributeJson,
                            dynamicAttributes,
                            custom_field_id:
                              dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))?.length > 0
                                ? dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))[0]?._id
                                : customFieldAttributeJson?.custom_field_id,
                          },
                        ]);
                      }}>
                      <PlusIcon className="h-4 w-4 stroke-2" /> Add SSO attribute
                    </Button>
                  }
                />
              </div>
            ) : (
              <div className="space-y-2">
                {customFieldsAttribute.map((field, index) => (
                  <div key={field?._id || field?.id}>
                    <SSOAttributeMapper
                      type={"user"}
                      value={field.value}
                      setValue={(value) => {
                        setCustomFieldUndoButtonDisabled(false);
                        setCustomFieldsAttribute((prevData) => [
                          ...prevData.map((data, prevIndex) =>
                            prevIndex === index
                              ? {
                                  ...data,
                                  value,
                                }
                              : data,
                          ),
                        ]);
                      }}
                      customFieldAttributeDropdownData={field?.dynamicAttributes
                        .filter((dynamicAttribute) => field.custom_field_id?.toString() === dynamicAttribute?._id?.toString() || !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))
                        .map((dynamicAttribute) => ({
                          value: dynamicAttribute?._id,
                          key: dynamicAttribute?.label,
                        }))}
                      customFieldAttributeIndex={
                        field?.dynamicAttributes
                          .filter((dynamicAttribute) => field.custom_field_id?.toString() === dynamicAttribute?._id?.toString() || !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))
                          .findIndex((dynamicAttribute) => dynamicAttribute?._id?.toString() === field?.custom_field_id?.toString()) || 0
                      }
                      setCustomFieldAttributeOption={(option) => {
                        setCustomFieldUndoButtonDisabled(false);
                        setCustomFieldsAttribute((prevData) => [
                          ...prevData.map((prevItem, prevIndex) =>
                            prevIndex === index
                              ? {
                                  ...prevItem,
                                  custom_field_id: option.value,
                                }
                              : prevItem,
                          ),
                        ]);
                      }}
                      removeData={() => {
                        setCustomFieldUndoButtonDisabled(false);
                        setCustomFieldsAttribute((prevData) => [...prevData.filter((_, prevIndex) => prevIndex !== index)]);
                      }}
                    />
                  </div>
                ))}
                <div className="flex w-full items-center justify-end space-x-4 pr-4 pt-2">
                  <Button
                    disabled={!dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))?.length}
                    version="secondary"
                    hoverText="Map attribute to user attribute"
                    onClick={() => {
                      setCustomFieldUndoButtonDisabled(false);
                      setCustomFieldsAttribute((prevData) => [
                        ...prevData,
                        {
                          ...customFieldAttributeJson,
                          dynamicAttributes,
                          custom_field_id:
                            dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))?.length > 0
                              ? dynamicAttributes.filter((dynamicAttribute) => !customFieldsAttribute.map((data) => data?.custom_field_id?.toString()).includes(dynamicAttribute?._id?.toString()))[0]?._id
                              : customFieldAttributeJson?.custom_field_id,
                        },
                      ]);
                    }}>
                    <PlusIcon className="h-4 w-4 stroke-2" /> Add SSO attribute
                  </Button>
                </div>
              </div>
            )}
          </>
        </Section>
      </EditContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, { manageSiteSettings, getSso, updateSsoWorkspaceGroupSettings, updateSsoCustomFieldSettings })(SSOServiceAttribute);
