import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { manageSiteSettings } from "src/actions/site";
import { getWorkspaceSiteEmail, manageWorkspaceSiteEmail } from "src/actions/workspace";
import validator from "validator";
import Button from "src/components/Shared/Buttons/Button";
import Section from "src/components/Shared/Containers/Section";
import Input from "src/components/Shared/Forms/Inputs/Input";

const SiteEmailSettings = ({ siteData, manageSiteSettings, workspaceId = null }) => {
  const [siteEmail, setSiteEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      setIsDisabled(true);

      let body = {
        site_email: siteEmail,
      };

      let message;
      if (workspaceId) {
        message = await manageWorkspaceSiteEmail({ workspace_id: workspaceId, ...body });
      } else {
        message = await manageSiteSettings(body, "/settings/smtp/support-email");
      }
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDisabled(true);
      setIsLoading(false);
    }
  };

  // const onClear = () => {
  //   setIsDisabled(true);
  // };

  const getDefaultValue = (data) => {
    setSiteEmail(data?.site_email || "");
  };

  const getWorkspaceSmtp = async () => {
    const data = await getWorkspaceSiteEmail({ workspace_id: workspaceId });
    getDefaultValue(data);
  };

  useEffect(() => {
    if (workspaceId) {
      getWorkspaceSmtp();
    } else {
      getDefaultValue(siteData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteData, workspaceId]);

  return (
    <Section>
      {/* <H3 caption="Enter your domain and edit any additional settings.">Authenticate your domain</H3> */}
      <div className="grid space-y-4 sm:space-y-8">
        <div className="flex w-full flex-wrap justify-between">
          <div className="flex w-full flex-wrap gap-y-6">
            <div className="-mx-2 sm:-mx-4">
              <div className="mb-4 pt-2">
                <p className="text-gray-800">Decide on an email address you'd like for support email.</p>
                <p className="text-sm text-gray-400">User will contact you based on this email address.</p>
              </div>
              <div className="flex w-full gap-x-2">
                <Input
                  label={
                    <p>
                      Support contact <span className="pl-2 text-gray-300">*optional email</span>
                    </p>
                  }
                  value={siteEmail}
                  onChange={(e) => {
                    setSiteEmail(e.target.value);
                    setIsDisabled(false);
                  }}
                  inline={true}
                />{" "}
                <Button
                  disabled={isDisabled || !validator.isEmail(siteEmail)}
                  onClick={onSubmit}
                  type="submit">
                  Save{isLoading ? <ArrowPathIcon className="ml-1 h-5 w-5 animate-spin" /> : <></>}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    siteData: state.site,
  };
};

export default connect(mapStateToProps, { manageSiteSettings })(SiteEmailSettings);
