import ToggleHeader from "src/components/Shared/Forms/Toggles/ToggleHeader";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import { CheckIcon, NoSymbolIcon } from "@heroicons/react/24/outline";
import { manageWorkspaceSettings } from "src/actions/workspace";
import Toggle from "src/components/Shared/Forms/Toggles/Toggle";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useEffect } from "react";
import InformationAlert from "src/components/Shared/Alerts/InformationAlert";

const WorkspaceSSOProvider = ({ ...props }) => {
  const [useWorkspaceSso, setUseWorkspaceSso] = useState(false);

  const handleUpdate = async (updateSettings) => {
    try {
      const message = await props.manageWorkspaceSettings({ workspace_id: props?.workspaceDetails?._id, ...updateSettings }, "/workspaces/:workspace_id/settings/sso-provider");
      toast.success(message);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    setUseWorkspaceSso(props.workspaceDetails?.use_workspace_sso);
  }, [props.workspaceDetails]);

  return (
    <EditContainer
      title="SSO Provider"
      subtitle="Connect up your company's secure login"
      defaultOptions={{
        onSuccessButtonVisible: !props.workspaceDetails?.domain || !props?.site?.allow_subdomains ? false : true,
        onCancelButtonVisible: !props.workspaceDetails?.domain || !props?.site?.allow_subdomains ? false : true,
      }}
      preview={{
        style: "success",
        isSuccess: useWorkspaceSso,
        text: useWorkspaceSso ? (
          <>
            <CheckIcon className="h-5 w-5" /> Enabled
          </>
        ) : (
          <>
            <NoSymbolIcon className="h-5 w-5" /> Disabled
          </>
        ),
      }}
      onSuccess={() => handleUpdate({ use_workspace_sso: useWorkspaceSso })}
      onCancel={() => setUseWorkspaceSso(props.workspaceDetails?.use_workspace_sso)}>
      <div className="min-w-[260px]">
        <ToggleHeader
          title="Allow SSO provider"
          subtitle="Workspace SSO will override defaults."
          position="left">
          <Toggle
            disabled={!props.workspaceDetails?.domain || !props?.site?.allow_subdomains ? true : false}
            checked={useWorkspaceSso}
            onChange={() => {
              setUseWorkspaceSso(!useWorkspaceSso);
            }}
          />
        </ToggleHeader>
        {!props.workspaceDetails?.domain || !props?.site?.allow_subdomains ? (
          <InformationAlert
            content="A subdomain needs to be added for SSO functionality to be enabled."
            type="info"
          />
        ) : (
          ""
        )}
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
    site: state.site,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorkspaceSSOProvider);
