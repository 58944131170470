import PlanDetails from "src/components/Workspaces/Workspace/BillingAndPayments/PlanDetails";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import Transactions from "./tabComponents/transactions/Transactions";
import { BanknotesIcon, ClipboardDocumentCheckIcon, CreditCardIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import Tabs from "src/components/Shared/Tabs/Tabs";
import { apiRequest } from "src/async/apiUtils";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "src/components/Shared/Buttons/Button";
import AssignPlanModal from "./AssignPlanModal";
import PaymentMethod from "./tabComponents/PaymentMethod";
import BillingContacts from "./tabComponents/BillingContacts";

const WorkspaceBillingAndPayments = ({ workspaceDetails, site }) => {
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState({});
  const [plans, setPlans] = useState([]);
  const [assignModal, setAssignModal] = useState(false);

  const loadPlanDetails = async () => {
    if (workspaceDetails?._id) {
      try {
        setLoading(true);
        const { data } = await apiRequest("get", `workspaces/${workspaceDetails?._id}/billing/plan`);
        setPlanDetails(data?.data);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    loadPlanDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDetails]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await apiRequest("get", "/plans");
        setPlans(response?.data?.data);
      } catch (err) {}
    };
    fetchPlans();
  }, []);

  const tabs = [
    { name: "Payment History", icon: ClipboardDocumentCheckIcon, component: <Transactions workspaceId={workspaceDetails?._id} /> },
    // { name: "Invoice Settings", icon: AdjustmentsHorizontalIcon, component: <InvoiceSettings/> },
    // { name: "Invoices", icon: DocumentTextIcon, component: <Invoices/> },
    { name: "Payment Method", icon: CreditCardIcon, component: <PaymentMethod workspaceId={workspaceDetails?._id} /> },
    { name: "Billing Contacts", icon: UserGroupIcon, component: <BillingContacts workspaceId={workspaceDetails?._id} /> },
  ];

  return (
    <div className="w-full gap-3">
      {loading ? (
        <Preloader />
      ) : (
        <div>
          <div className="">
            <div className="w-1/2">
              {planDetails?.active_plan ? (
                <PlanDetails planDetails={planDetails?.active_plan} />
              ) : (
                <div className="flex flex-col items-center justify-center rounded-lg border-2 border-dashed p-6 pt-8">
                  <Button
                    version="primary"
                    onClick={() => setAssignModal(true)}>
                    <div className="flex gap-x-3">
                      <BanknotesIcon className="h-5 w-5 stroke-2" /> Assign Plan
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
          {planDetails?.active_plan && (
            <div className=" mt-5 w-full border-t pt-5">
              <Tabs
                altDesign={true}
                changeParams={false}
                tabs={tabs}
              />
            </div>
          )}
        </div>
      )}
      <AssignPlanModal
        isOpen={assignModal}
        setIsOpen={setAssignModal}
        setRefresh={loadPlanDetails}
        defaultPlan={planDetails?.active_plan?.plan_details?._id || ""}
        title={`${workspaceDetails?.name}`}
        plans={plans}
        secondaryTitle={"Billing / Plan Assign"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(WorkspaceBillingAndPayments);
