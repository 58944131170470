import ShareSmtpSettings from "./ShareSmtpSettings";
import SiteEmailSettings from "./SiteEmailSettings";
import Section from "../../Shared/Containers/Section";
import OptionTabs from "../../Shared/Tabs/OptionTabs";
import OwnSmtpSettings from "./OwnSmtpSettings";
import { H3 } from "../../Shared/Text/Headers";
import { connect } from "react-redux";
import React from "react";

const SmtpSettings = ({ site, workspaceId = null }) => {
  return (
    <Section className="space-y-4">
      <SiteEmailSettings workspaceId={workspaceId} />
      <H3 caption="Adjust settings to allow for your clients to receive emails from your domain.">SMTP server settings</H3>
      <OptionTabs
        tabs={[
          {
            name: "I don't have a SMTP server",
            description: "This is typically the best option. Verify your domain (or a subdomain) and verifying an email and you can immediately start using our SMTP server.",
            component: <ShareSmtpSettings workspaceId={workspaceId} />,
            isHighlighted: site?.default_shared_smtp,
          },
          {
            name: "Use my own SMTP server (Advanced)",
            description: "If you have your own SMTP server for sending emails, then select this option and enter in your credentials.",
            component: <OwnSmtpSettings workspaceId={workspaceId} />,
            isHighlighted: !site?.default_shared_smtp,
          },
        ]}
        activeTab={site?.default_shared_smtp ? "I don't have a SMTP server" : "Use my own SMTP server (Advanced)"}
      />
    </Section>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state?.site,
  };
};

export default connect(mapStateToProps, {})(SmtpSettings);
