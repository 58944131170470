import { PlusIcon } from "@heroicons/react/24/outline";
import { Fragment, useCallback, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getPages } from "src/actions/page";
import { deleteGroupData, getGroupData, getUsers } from "src/actions/user";
import GroupEntry from "src/components/Groups/GroupEntry";
import Button from "src/components/Shared/Buttons/Button";
import Image from "src/components/Shared/Image";
import Modal from "src/components/Shared/Modal";
import PaginationFooter from "src/components/Shared/PaginationFooter";
import Search from "src/components/Shared/Search";
import TRHeader from "src/components/Shared/Table/TRHeader";
import Table from "src/components/Shared/Table/Table";
import TableOptions from "src/components/Shared/TableOptions";
import { noFavicon } from "src/config/host";
import useFetch from "src/hooks/useFetch";
import GroupModalAdd from "./GroupModalAdd";
import { apiRequest } from "src/async/apiUtils";
import PagePreviewModal from "src/components/Pages/PageModals/PagePreviewModal";
import useWindowDimensions from "src/hooks/useWindowDimensions";

const trustedAttributeArr = [
  {
    _id: "user.fullName",
    value: "user.fullName",
    name: "Full Name",
  },
  {
    _id: "user.email",
    value: "user.email",
    name: "Email Address",
  },
];

const GroupList = ({ ...props }) => {
  const [limit, setLimit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [keyword, setKeyword] = useState("");

  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteDisable, setIsDeleteDisable] = useState(null);

  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [addGroupStatus, setAddGroupStatus] = useState(false);
  const [editGroupStatus, setEditGroupStatus] = useState(false);
  const [editId, setEditId] = useState(null);
  const [customFields, setCustomFields] = useState([]);

  const user = useSelector((state) => state.auth.user);
  const { width } = useWindowDimensions();

  const [groups, setGroups] = useState([]);
  const [meta, setMeta] = useState(null);
  const [groupsLoaded, setGroupsLoaded] = useState(false);

  // Page preview
  const [prevModalOpen, setPrevModalOpen] = useState(false);
  const [prevPageDetails, setPrevPageDetails] = useState(null);
  const [isPagePreview, setIsPagePreview] = useState(false);

  const {
    response: { data: users },
  } = useFetch("/users/list", { query: { active_status: true } });

  const {
    response: { data: operators },
  } = useFetch("/operators/list");

  const {
    response: { data: activeSSO },
  } = useFetch("/sso/is-active");

  const getGroupList = useCallback(async () => {
    try {
      setGroupsLoaded(false);
      const res = await apiRequest("get", `groups`, {
        queries: { limit: limit || 20, offset: offset || 0, keyword: keyword || "" },
      });

      if (res.data.status === 200) {
        setGroups(res.data.data || []);
        setMeta(res.data.meta);
      } else {
        setGroups([]);
        setMeta(null);
      }
      setGroupsLoaded(true);
    } catch (error) {}
  }, [limit, offset, keyword]);

  const handlePaginationChange = ({ limit, offset }) => {
    setLimit(limit);
    setOffset(offset);
  };

  useEffect(() => {
    getGroupList();
  }, [getGroupList]);

  const removeGroup = async (e) => {
    try {
      setIsDeleteDisable(true);
      const message = await props.deleteGroupData(deleteId);
      setDeleteId(null);
      if (groups.length === 1 && offset !== 0) {
        setOffset(offset - 1);
      } else {
        getGroupList();
      }
      toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsDeleteDisable(false);
    }
  };

  const loadCustomFields = useCallback(() => {
    (async () => {
      try {
        const { data: resData } = await apiRequest("GET", `/users/custom-fields`);

        if (resData.status === 200 && resData.data) {
          setCustomFields(resData.data);
        }
      } catch (err) {
        console.dir("err", err);
      }
    })();
  }, []);

  useEffect(() => {
    loadCustomFields();
  }, [loadCustomFields]);

  const allTrustedAttributes = [
    ...trustedAttributeArr,
    ...customFields.map((field) => ({
      _id: `user.${field.code}`,
      value: `user.${field.code}`,
      name: field.label,
    })),
  ];

  const pagePreview = async (page) => {
    setIsPagePreview(true);
    setPrevPageDetails({ page, body: { user_id: props?.me?._id, page_id: page?.page_id, group_permission_id: page?._id } });
    setPrevModalOpen(true);
  };

  return (
    <>
      <TableOptions
        leftContent={
          <Search
            keyword={keyword}
            setKeyword={(val) => {
              setOffset(0);
              setKeyword(val);
            }}
            placeholder={"Search"}
            inline={true}
          />
        }
        rightContent={
          user?.type === "admin" ? (
            <Button
              version="secondary"
              hoverText="Add a group"
              onClick={() => {
                setGroupModalOpen(true);
                setAddGroupStatus(true);
                setEditGroupStatus(false);
                setEditId(null);
              }}>
              <PlusIcon className="h-4 w-4 stroke-[3px]" />
              {width > 640 ? "Add group" : "Group"}
            </Button>
          ) : (
            <></>
          )
        }
      />

      <Table
        tableHeader={
          <TRHeader>
            <th
              scope="col"
              className="overflow-hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Name
            </th>
            <th
              scope="col"
              className="overflow-hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Pages
            </th>
            <th
              scope="col"
              className="overflow-hidden rounded-tl-lg py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
              Users
            </th>
            <th
              scope="col"
              className="overflow-hidden rounded-tr-lg px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
          </TRHeader>
        }
        colSpan="5"
        loaded={groupsLoaded}
        dataExists={groups?.length > 0}>
        {groups?.map((group, i) => {
          return (
            <Fragment key={group._id}>
              {group?.workspace_id?.name !== groups[i - 1]?.workspace_id?.name && (
                <tr>
                  <td
                    colSpan={4}
                    className="whitespace-pre-wrap break-all bg-gray-100 px-4 py-4 text-sm font-semibold text-gray-500 sm:py-3 sm:pl-6">
                    <div className="flex items-center gap-4">
                      <div
                        style={{ backgroundColor: group?.workspace_id?.top_bar_color }}
                        className="h-10 w-10 overflow-hidden rounded">
                        <Image
                          image={group?.workspace_id?.square_logo || group?.workspace_id?.image_favicon || group?.workspace_id?.image_logo}
                          failImage={noFavicon}
                          alt={"Workspace group"}
                        />
                      </div>
                      {group?.workspace_id?.name}
                    </div>
                  </td>
                </tr>
              )}
              <GroupEntry
                key={group._id}
                group={group}
                deleteId={deleteId}
                setDeleteId={setDeleteId}
                setEditId={setEditId}
                setGroupModalOpen={setGroupModalOpen}
                setAddGroupStatus={setAddGroupStatus}
                setEditGroupStatus={setEditGroupStatus}
              />
            </Fragment>
          );
        })}
      </Table>
      <PaginationFooter
        itemName="Group record"
        limit={limit}
        offset={offset}
        count={meta?.count}
        onChange={handlePaginationChange}
      />

      <Modal
        title="Group"
        secondaryTitle="Delete"
        isOpen={!!deleteId}
        onCancel={() => setDeleteId(null)}
        onSuccess={removeGroup}
        isLoading={isDeleteDisable}
        defaultOptions={{
          onSuccessButtonText: "Delete",
        }}>
        <div className="grid gap-y-8 whitespace-nowrap text-sm text-gray-500">Are you sure you want to delete {groups?.find((item) => item._id === deleteId)?.name}?</div>
      </Modal>
      <GroupModalAdd
        isOpen={groupModalOpen}
        setIsOpen={setGroupModalOpen}
        setRefresh={() => getGroupList()}
        activeSSO={activeSSO}
        defaultOptions={{
          onSuccessButtonText: "Save",
        }}
        defaultStyles={{
          overFlowYVisible: false,
        }}
        title="Group"
        secondaryTitle={editGroupStatus ? "Edit" : "Add"}
        addGroupStatus={addGroupStatus}
        setAddGroupStatus={setAddGroupStatus}
        editGroupStatus={editGroupStatus}
        setEditGroupStatus={setEditGroupStatus}
        editId={editId}
        setEditId={setEditId}
        groups={groups}
        trustedAttributeArr={allTrustedAttributes}
        isPagePreview={isPagePreview}
        pagePreview={pagePreview}
        users={users}
        operators={operators}
      />
      {/* { Page Preview} */}
      <PagePreviewModal
        pageDetails={prevPageDetails}
        isOpen={prevModalOpen}
        onCancel={() => {
          setPrevModalOpen(false);
          setPrevPageDetails(null);
          setIsPagePreview(false);
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    me: state.auth.user,
  };
};

export default connect(mapStateToProps, { getGroupData, deleteGroupData, getPages, getUsers })(GroupList);
