import WorkspaceSubscriberInstance from "../Workspaces/WorkspaceSubscriberInstance";
import TableRow from "src/components/Shared/Table/TableRow";
import { getStatusStyles } from "src/helpers/billingHelper";
import { noWorkspaceImage } from "src/config/host";
import Image from "../Shared/Image";
import { Link } from "react-router-dom";
import { classNames } from "src/helpers/classNames";

const BillingWorkspaceEntry = ({ data, ...props }) => {
  const { className, label } = getStatusStyles(data?.transaction?.subscription_status);
  return (
    <TableRow keyId={data?._id}>
      <td className="break-word block w-full whitespace-pre-wrap py-0  text-sm text-gray-500 ">
        <Link
          to={`/workspaces/${data._id}`}
          className="group w-full cursor-pointer rounded-md border border-transparent pr-5 font-medium text-gray-900 transition-all duration-200">
          <div className="flex w-full items-center">
            <div
              style={!data?.square_logo && data.image_logo ? { background: data.top_bar_color } : {}}
              className="mx-6 h-8 w-8 overflow-hidden rounded bg-white/10">
              <Image
                image={data.square_logo || data.image_logo || data.image_favicon}
                failImage={noWorkspaceImage}
                alt={"Workspace"}
              />
            </div>
            <div className="flex text-sm">
              <div className="flex h-full  items-start justify-start gap-x-4 overflow-visible truncate text-gray-600 group-hover:text-gray-900 group-hover:underline ">
                <span className="text-start  text-gray-600">{data?.name}</span>
                <div className="ml-0.5 border">{data?.workspace_type === "JWT_FULL_EMBED" && <WorkspaceSubscriberInstance />}</div>
              </div>
            </div>
          </div>
        </Link>
      </td>
      {!props?.isNocharge && (
        <>
          <td className="break-word block w-full whitespace-pre-wrap px-6 py-0 pt-3 text-xs text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
            <div className={classNames("flex h-6 w-20 items-center justify-center gap-2 rounded border px-2 py-1", className)}>{label}</div>
          </td>
          <td className="break-word block w-full whitespace-pre-wrap px-6 py-0 pt-3 text-xs text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
            {data?.user ? (
              <div className="flex flex-col items-start justify-start ">
                <div>{data?.user?.name}</div>
                <div className="text-xs text-slate-400">{data?.user?.email}</div>
              </div>
            ) : (
              <div>*Not Assigned*</div>
            )}
          </td>
        </>
      )}
    </TableRow>
  );
};

export default BillingWorkspaceEntry;
