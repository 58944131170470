import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiRequest } from "src/async/apiUtils";
import useFetch from "src/hooks/useFetch";
import { v4 } from "uuid";
import Button from "src/components/Shared/Buttons/Button";
import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Preloader from "src/components/Shared/LoadingAnimations/Preloader";
import QuickMenu from "src/components/Shared/QuickMenu";
import WarningModal from "src/components/Shared/WarningModal";
import FormSectionAction from "./FormActions/FormSectionAction";
import FormContent from "./FormContent";
import formJson from "./FormJson/formJson";
import ReorderSectionsModal from "./FormModals/ReorderSectionsModal";

const FormQuestion = ({ name, setName = () => {}, ...props }) => {
  // "id" is the _id of an existing form template
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState(formJson.formJson);
  const [prevData, setPrevData] = useState(formJson.formJson);
  const [disabled, setDisabled] = useState(false);
  const [deleteSectionData, setDeleteSectionData] = useState(null);
  const [moveModalOpen, setMoveModalOpen] = useState(false);
  const [formsLoading, setFormsLoading] = useState(true);

  const {
    response: { data: workspaces },
  } = useFetch("/workspaces/list", { method: "post" });

  const handleSelectedWorkspaces = (workspacesArray) => {
    let updatedSelectedWorkspaces = workspacesArray.reduce((workspaces, workspace) => (workspace.selected ? [...workspaces, workspace.key] : workspaces), []);
    handleOnChange("workspace_ids", updatedSelectedWorkspaces);
  };

  const handleOnChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleOnDuplicateSection = (section) => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData.sections,
        {
          ...section,
          id: v4(),
          contents: [
            ...section.contents.map((content) => ({
              ...content,
              id: v4(),
              options: [
                ...content?.options?.map((prevOption) => ({
                  ...prevOption,
                  id: v4(),
                })),
              ],
              rows: [
                ...(content?.rows || [])?.map((prevRow) => ({
                  ...prevRow,
                  id: v4(),
                })),
              ],
            })),
          ],
        },
      ],
    }));
  };

  const handleOnMoveSectionModalOpen = () => {
    setMoveModalOpen(true);
  };

  const handleOnDeleteSectionModalOpen = (section) => {
    setDeleteSectionData(section);
  };

  const handleOnDeleteSection = () => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [...prevData.sections.filter((prevSection) => prevSection?.id !== deleteSectionData?.id)],
    }));
    setDeleteSectionData(null);
  };

  const getFormDetails = useCallback(async () => {
    try {
      if (id) {
        setFormsLoading(true);
        const { data } = await apiRequest("get", searchParams.get("workspace_id") ? `/workspaces/:workspace_id/forms/:form_id` : `/forms/:form_id`, {
          params: { workspace_id: searchParams.get("workspace_id"), form_id: id },
        });
        if (data?.data) {
          const newData = {
            ...data?.data,
            sections: [
              ...data?.data?.sections?.map((prevSection) => ({
                ...prevSection,
                id: v4(),
                contents: [
                  ...prevSection?.contents?.map((prevContent) => ({
                    ...prevContent,
                    id: v4(),
                    options: [
                      ...prevContent?.options?.map((prevOption) => ({
                        ...prevOption,
                        id: v4(),
                      })),
                    ],
                    rows: [
                      ...(prevContent?.rows || [])?.map((prevRow) => ({
                        ...prevRow,
                        id: v4(),
                      })),
                    ],
                  })),
                ],
              })),
            ],
          };
          setFormData(newData);
          setPrevData(newData);
          setName(data?.data?.name);
        } else {
          navigate("/");
        }
      }
    } catch (error) {
      if (error.message !== "CanceledError: canceled") {
        navigate("/");
      }
    } finally {
      setFormsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, id, navigate]);

  const onHandleSubmit = async () => {
    try {
      setDisabled(true);
      let workspaceId = searchParams.get("workspace_id");

      let url = workspaceId ? "/workspaces/:workspace_id/forms" : "/forms";
      url += id ? "/:form_id" : "";

      let method = id ? "PUT" : "POST";

      const { data } = await apiRequest(method, url, {
        body: formData,
        params: { workspace_id: workspaceId, form_id: id },
      });
      toast.success(data.message);
      setPrevData(formData);

      // If this is a newly created form
      if (!id) {
        let navigationUrl = "";
        if (formData?.is_global) {
          // Go to the global forms page
          navigationUrl = "/forms?tab=Global+forms";
        } else if (workspaceId) {
          // Go to the workspace forms page
          navigationUrl = `/workspaces/${workspaceId}?tab=Forms`;
        } else {
          // Go to the basic "all forms" page
          navigationUrl = "/forms";
        }
        navigate(navigationUrl);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDisabled(false);
    }
  };

  const onHandleClear = () => {
    setFormData(prevData);
    setName(prevData?.name);
  };

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      name,
    }));
  }, [name]);

  useEffect(() => {
    if (!id) {
      const workspaceIdParam = searchParams.get("workspace_id");
      let workspaceIds = [];

      if (workspaceIdParam) {
        try {
          const parsed = JSON.parse(decodeURIComponent(workspaceIdParam));
          workspaceIds = Array.isArray(parsed) ? parsed : [parsed];
        } catch (error) {
          workspaceIds = [workspaceIdParam];
        }
      }

      const isGlobal = searchParams.get("is_global") === "true";

      handleOnChange("workspace_ids", workspaceIds);
      handleOnChange("is_global", isGlobal);
    }
  }, [id, searchParams]);

  useEffect(() => {
    getFormDetails();
  }, [getFormDetails]);

  return (
    <div className="w-full max-w-5xl space-y-12">
      {!id || !formsLoading ? (
        <>
          {(!id || (id && formData?.is_global)) && !searchParams.get("pathname").includes("/workspaces") && (
            <div className="pr-16">
              <div className="relative w-full space-y-4 rounded-md border-t-4 border-highlightColor bg-white px-6 py-6 shadow">
                <div className="flex flex-col gap-4">
                  <div className="min-w-60 flex-shrink-0">
                    {formData?.is_global ? (
                      <MultiSelectObject
                        inline={true}
                        defaultOptions={workspaces
                          .filter((item) => item.workspace_type !== "JWT_FULL_EMBED")
                          .map((wrk) => {
                            return { key: wrk._id, value: wrk.name, selected: formData?.workspace_ids?.includes(wrk._id), object: wrk };
                          })}
                        searchableFields={["name"]}
                        title="Assign workspaces"
                        onChange={handleSelectedWorkspaces}
                      />
                    ) : (
                      !id && (
                        <SelectMenu
                          inline={true}
                          label={"Select Workspace"}
                          emptyListText={"Select an option"}
                          startIndex={workspaces?.filter((item) => item.workspace_type !== "JWT_FULL_EMBED")?.findIndex((wrk) => formData?.workspace_ids?.includes(wrk._id))}
                          options={workspaces
                            .filter((item) => item.workspace_type !== "JWT_FULL_EMBED")
                            .map((wrk) => {
                              return { key: wrk.name, value: wrk._id, object: wrk };
                            })}
                          dropdownClass={"w-full"}
                          searchableFields={["name"]}
                          setOption={(e) => handleOnChange("workspace_ids", [e.value])}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {formData?.sections &&
            formData?.sections?.length > 0 &&
            formData?.sections?.map((section, sectionIndex) => (
              <div
                className="flex w-full gap-4"
                key={section?.id}>
                <div className="flex-shrink flex-grow">
                  <div className="space-y-6">
                    {formData?.sections?.length > 1 && (
                      <div className="-mb-6 flex pl-1">
                        <div className="rounded-t-xl bg-highlightColor px-6 py-2 text-base font-semibold text-white">{`Section ${sectionIndex + 1} of ${formData?.sections?.length}`}</div>
                        <div className="ml-auto">
                          <QuickMenu
                            items={[
                              { name: "Duplicate Section", onClick: () => handleOnDuplicateSection(section), color: "text-grey-500 hover:text-highlightColor" },
                              { name: "Move Section", onClick: () => handleOnMoveSectionModalOpen(), color: "text-grey-500 hover:text-highlightColor" },
                              { name: "Delete Section", onClick: () => handleOnDeleteSectionModalOpen(section), color: "text-red-500" },
                            ]}
                          />
                        </div>
                      </div>
                    )}
                    <FormContent
                      section={section}
                      setFormData={setFormData}
                    />
                  </div>
                </div>
                <FormSectionAction
                  section={section}
                  setFormData={setFormData}
                />
              </div>
            ))}

          <div className="mt-5 flex w-full justify-end gap-x-3 pr-16 pt-3">
            <Button
              version="gray"
              type="button"
              onClick={onHandleClear}>
              Clear All
            </Button>
            <Button
              type="button"
              onClick={onHandleSubmit}
              disabled={disabled}
              loading={disabled}>
              {id ? "Update" : "Create"}
            </Button>
          </div>
          <WarningModal
            isOpen={deleteSectionData?.id ? true : false}
            onSuccess={handleOnDeleteSection}
            onCancel={() => setDeleteSectionData(null)}
            type="DELETE_DATA"
          />
          <ReorderSectionsModal
            isOpen={moveModalOpen}
            formData={formData}
            setFormData={setFormData}
            onCancel={() => setMoveModalOpen(false)}
            onSuccess={setFormData}
          />
        </>
      ) : (
        <Preloader />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(FormQuestion);
