import TableRow from "src/components/Shared/Table/TableRow";
import { connect } from "react-redux";
import moment from "moment";
import { cardtypes, getStatusStyles } from "src/helpers/billingHelper";
import { classNames } from "src/helpers/classNames";
import { formatCurrency } from "src/helpers";

const TransactionEntry = ({ data, workspaceId, showPlanName }) => {
  const { className, label } = getStatusStyles(data?.payment_status, "transaction");

  return (
    <TableRow keyId={data?._id}>
      {showPlanName && (
        <td className="break-word block w-full whitespace-pre-wrap px-6 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
          <div className="font-medium text-gray-900">{data?.plan_details?.name}</div>
        </td>
      )}
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="font-medium text-gray-900">{moment(data?.created_at).format("MMM DD, YYYY")}</div>
      </td>
      <td className="break-word block w-full whitespace-pre-wrap px-3 py-0 pt-3 text-sm text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">
        <div className="font-medium capitalize text-gray-900">{`${formatCurrency(data?.amount)} | billed ${data?.subscription?.recurring_type}ly `}</div>
        {data?.payment_method_details?.card_last4 && (
          <div className=" flex items-center justify-start gap-1 text-xs text-gray-600">
            <div
              className="w-10"
              dangerouslySetInnerHTML={{ __html: data?.payment_method_details?.brand ? cardtypes[data?.payment_method_details?.brand] : "" }}></div>
            <span className="pt-1">{`XXXX-XXXX-XXXX-${data?.payment_method_details?.card_last4}`}</span>
          </div>
        )}
      </td>
      <td className="block w-full whitespace-pre-wrap break-all px-3 py-1 text-xs capitalize text-gray-500 sm:table-cell sm:w-auto sm:py-3.5">{workspaceId ? data?.plan_details?.name : data?.workspace?.name || <div className="text-gray-400">*Workspace Deleted*</div>}</td>
      <td className={"block w-full whitespace-pre-wrap break-all px-3 py-1 text-xs text-gray-500 sm:table-cell sm:w-auto sm:py-3.5"}>
        <div className={classNames("flex h-6 w-24 items-center justify-center gap-2 rounded-lg border px-2 text-xs", className)}>{label}</div>
      </td>
    </TableRow>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(TransactionEntry);
