import { RectangleStackIcon, TagIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { connect } from "react-redux";
import { formatCurrency } from "src/helpers";
import { classNames } from "src/helpers/classNames";
import BillingWorkspaceList from "./BillingWorkspaceList";

const BillingPlanBodyContent = ({ data = null, setLists = () => {}, ...props }) => {
  const [workspaceCount, setWorkspaceCount] = useState(0);

  return (
    <div className="flex-shrink-0">
      <div className="relative z-0 px-8 pb-6 pt-8">
        <div className="absolute left-0 top-0 z-[-1] flex h-80 w-full flex-col justify-end bg-[linear-gradient(45deg,_#edf1fe,_#f9faff,_#cfd1e7)]">
          <div className="h-20 w-full bg-gradient-to-t from-white"></div>
        </div>
        <div className="space-y-4">
          <div className="space-y-4">
            <div className="flex w-full items-center justify-between">
              <div className="flex  items-center gap-2 text-3xl font-bold">
                {data?.name || "Plan Name"} {data?.default && <div className={classNames("flex h-8 items-center justify-center gap-2 rounded border border-blue-500 bg-blue-50 px-2 text-base text-blue-500")}>Default</div>}
              </div>
              {workspaceCount > 0 && (
                <div className="inline-flex  rounded-full border bg-white px-4 py-2">
                  <RectangleStackIcon className="h-5 w-5 text-gray-600" />
                  <span className="ml-2 text-sm font-medium capitalize text-gray-600">{workspaceCount} workspaces</span>
                </div>
              )}
            </div>
            <div className="text-base text-gray-700">{data?.description || "No description provided."}</div>
          </div>
          <div className="">
            {data?.free_trial?.status && (
              <div className="inline-flex items-center rounded-full bg-white py-3 pl-4 pr-6">
                <TagIcon className="h-6 w-6 text-gray-600" />
                <span className="ml-3 text-base text-gray-600">{data?.free_trial?.days} day free trial</span>
              </div>
            )}
          </div>
          <div>
            <div className="grid grid-cols-2 gap-4">
              {data?.pricing?.rates?.map((price) => {
                let monthlyPlanPrice = price?.amount || 0;
                if (price?.interval === "year") {
                  monthlyPlanPrice = (monthlyPlanPrice - price?.discount_amount) / 12;
                }
                return (
                  <div
                    key={price?._id}
                    className="relative rounded-lg border border-slate-200 bg-white px-4 py-4">
                    <div className="absolute -top-2 left-3 flex">
                      <p className="rounded border border-slate-400 bg-white px-3 text-slate-600">{price?.interval === "year" ? "Yearly" : "Monthly"}</p>
                      {price?.discount_percentage > 0 && <p className="ml-2 rounded-full shadow bg-green-50 px-3 text-base font-medium text-green-600">Save {price?.discount_percentage || 0}%</p>}
                    </div>
                    <div className="pt-2">
                      <span className="text-xl font-bold text-gray-800">{formatCurrency(monthlyPlanPrice, data?.currency)}/month</span>
                    </div>
                    <div className="text-sm text-gray-400">
                      {`${formatCurrency(monthlyPlanPrice * 12, data?.currency)}/year`} {price?.discount_percentage > 0 && <span className="ml-2 text-base font-medium text-green-600">Save {formatCurrency(price?.interval === "year" ? price?.discount_amount : 0)}</span>}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="">
            <BillingWorkspaceList
              planId={data?._id}
              setWorkspaceCount={setWorkspaceCount}
              isNocharge={data?.pricing?.type === "no_charge"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    site: state.site,
  };
};

export default connect(mapStateToProps, {})(BillingPlanBodyContent);
