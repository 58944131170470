import "flatpickr/dist/themes/light.css";
import { classNames } from "src/helpers/classNames";

export default function TableOptions({ leftContent = null, middleContent = null, rightContent = null, wrapperClass, innerClass }) {
  return (
    <div className={classNames("mb-4 flex items-center justify-between", wrapperClass)}>
      <div className={classNames("flex w-full justify-between gap-2 sm:gap-0", innerClass)}>
        <div className="flex flex-shrink flex-grow flex-col gap-2 sm:w-7/12 sm:flex-row">
          {leftContent && <div className="w-full max-w-[400px]">{leftContent}</div>}
          {middleContent && <div className="-ml-3 -mb-1 md:mb-0 md:ml-0 md:flex justify-center gap-x-1 md:justify-start">{middleContent}</div>}
        </div>
        {rightContent && <div className="relative flex flex-shrink-0 items-center justify-end gap-x-1 sm:min-w-[140px]">{rightContent}</div>}
      </div>
    </div>
  );
}
