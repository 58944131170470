import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import EditContainer from "src/components/Shared/Containers/EditContainer";
import Input from "src/components/Shared/Forms/Inputs/Input";
import PillEntry from "../../PillEntry";
import { connect } from "react-redux";
import { manageWorkspaceSettings } from "src/actions/workspace";

const WorkspaceTags = ({ ...props }) => {
  const [tag, setTag] = useState("");
  const [tagList, setTagList] = useState([]);

  const handleUpdate = async (updateSettings) => {
    try {
      const message = await props.manageWorkspaceSettings({ workspace_id: props?.workspaceDetails?._id, ...updateSettings }, "/workspaces/:workspace_id/settings/tags");
      toast.success(message);
    } catch (error) {
    } finally {
    }
  };

  const removeTag = async (index) => {
    tagList.splice(index, 1);
    setTagList([...tagList]);
  };

  const handleTag = async (e) => {
    if ((e.code === "Enter" || e.code === "Comma" || e.submit === true) && tag.trim()) {
      if (e.submit !== true) {
        e.preventDefault();
      }
      if (tagList.findIndex((tg) => tg.toLowerCase().trim() === tag.toLowerCase().trim()) === -1) {
        const tags = [...tagList, tag.trim()];
        setTagList(tags);
        setTag("");
      }
    }
  };

  useEffect(() => {
    setTagList(props.workspaceDetails?.tags || []);
  }, [props.workspaceDetails]);

  return (
    <EditContainer
      title="Workspace tags"
      subtitle="Add tags for filtering sso data"
      preview={{
        text: tagList.length ? (
          <>
            {tagList.length} tag{tagList.length > 1 && "s"}
          </>
        ) : (
          "No tag"
        ),
      }}
      onSuccess={() => handleUpdate({ tags: tagList })}
      onCancel={() => setTagList(props.workspaceDetails?.tags || [])}>
      <div className="min-w-[260px] flex-shrink">
        <div className="mb-4 space-y-4">
          <div className="w-96">
            <div className="flex w-full justify-end">
              <p className="mb-.5 text-xs text-gray-300">Hit return to add tag to collection.</p>
            </div>
            <Input
              type="text"
              autoComplete="off"
              name="tag-name"
              label="Tag"
              inline={true}
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              onKeyDown={handleTag}
            />
          </div>
          {tagList?.length > 0 && (
            <div className="flex flex-wrap items-center gap-2">
              {tagList?.map((tag, index) => {
                return (
                  <PillEntry
                    index={index}
                    tag={tag}
                    onDelete={removeTag}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </EditContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};

export default connect(mapStateToProps, { manageWorkspaceSettings })(WorkspaceTags);
