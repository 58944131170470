import SelectMenu from "src/components/Shared/Forms/Selects/SelectMenu";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";

const AssignPlanModal = ({ isOpen = false, title = "", secondaryTitle = "", workspaceDetails, setRefresh = () => {}, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const { data } = await apiRequest("put", `workspaces/${workspaceDetails?._id}/billing/plan/${selectedPlan}/assign`, {});
      if (data.status === 200) {
        setRefresh();
        props.setIsOpen(!isOpen);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setSelectedPlan(props?.defaultPlan);
  }, [props?.defaultPlan]);

  return (
    <Modal
      title={title}
      secondaryTitle={secondaryTitle}
      isLoading={isLoading}
      isOpen={isOpen}
      onCancel={() => {
        props.setIsOpen(!isOpen);
      }}
      onSuccess={onSubmit}>
      <div className="relative w-full space-y-4">
        <div className="relative mb-4 w-full">
          <SelectMenu
            label="Assign Plan"
            inline={true}
            startIndex={props?.plans?.findIndex((item) => item?._id === selectedPlan)}
            options={props?.plans?.map((pln) => ({ key: pln?.name, value: pln?._id }))}
            setOption={(option) => {
              setSelectedPlan(option.value);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    workspaceDetails: state.workspaceDetails,
  };
};
export default connect(mapStateToProps, {})(AssignPlanModal);
