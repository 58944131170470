import MultiSelectObject from "src/components/Shared/Forms/Selects/MultiSelectObject/MultiSelectObject";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import Modal from "src/components/Shared/Modal";
import { apiRequest } from "src/async/apiUtils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import useUrlQueryParams from "src/hooks/useUrlQueryParams";

const GroupAssignToUserModal = ({ isOpen = false, title = "", secondaryTitle = "", defaultWorkspaceId = "", setRefresh = () => {}, ...props }) => {
  const { user: user_id } = useUrlQueryParams();
  const navigate = useNavigate();
  const [selectedGroup, setSelectedGroup] = useState([]);

  const handleSelectedGroups = (groupsArray) => {
    let updatedSelectedGroups = groupsArray.reduce((groups, group) => (group.selected ? [...groups, group.key] : groups), []);
    setSelectedGroup(updatedSelectedGroups);
  };

  const onSubmit = async () => {
    try {
      const params = { workspace_id: defaultWorkspaceId, user_id };
      const { data } = await apiRequest("post", `/workspaces/:workspace_id/users/:user_id/group-assign`, {
        body: {
          groups: selectedGroup,
        },
        params,
      });
      if (data.status === 200) {
        setRefresh();
        props.setIsOpen(!isOpen);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setSelectedGroup(props?.defaultGroups);
  }, [props?.defaultGroups]);

  const redirectToGroupAdd = () => {
    navigate(`/workspaces/${defaultWorkspaceId}?tab=Groups&openGroupManageModal=true`);
  };

  return (
    <Modal
      title={title}
      secondaryTitle={secondaryTitle}
      isOpen={isOpen}
      onCancel={() => {
        props.setIsOpen(!isOpen);
      }}
      onSuccess={onSubmit}
      defaultOptions={{
        onSuccessButtonText: "Save",
      }}>
      <div className="relative w-full space-y-4">
        <div className="relative mb-4 w-full">
          <MultiSelectObject
            defaultOptions={props?.groups?.map((group) => {
              return { key: group._id, value: group.name, selected: selectedGroup.includes(group?._id), object: group };
            })}
            searchableFields={["name"]}
            title="Assign groups"
            onChange={handleSelectedGroups}
            inline={true}
          />
        </div>
      </div>
      {props?.groups?.length === 0 && (
        <div className="relative  flex w-full">
          <div
            className="group flex items-center gap-x-2 rounded border border-transparent px-2 py-1 text-gray-600 transition-all duration-200 hover:cursor-pointer hover:border-highlightColor hover:text-highlightColor"
            onClick={redirectToGroupAdd}>
            <SquaresPlusIcon className="h-6 w-6" />
            Add a Group
          </div>
        </div>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    site: state.site,
  };
};
export default connect(mapStateToProps, {})(GroupAssignToUserModal);
